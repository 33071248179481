import React, { useCallback, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/Footer";
import Modal from "../layouts/modal";
import PageCounter from "./counter";
import ClientTestimonials from "./testimonials";
import PagePricing from "./PagePricing";
import PageBlog from "./pageblog";
import PageFAQ from "./pagefaq";
import PageSubscribe from "./pagesubscribe";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { api_url } from "../basic";
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {selectUser,logout,selectPage_id,page_id} from '../../app/userSlice';
const CheckoutPage = () => {
  const user = useSelector(selectUser);
  const { search } = useLocation();
  let pid = new URLSearchParams(search).get("pid");
  let cid = new URLSearchParams(search).get("cid");
  const [plans, setPlans] = useState([]);
  const [planid, setPlanid] = useState(pid != "" ? pid : "");
  const [cmpid, setCmpid] = useState(cid != "" ? cid : "");

  const [planData, setPlanData] = useState("");
  const [planAmt, setPlanAmt] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [gst, setGst] = useState(0);
  const [servicetax, setServicetax] = useState(0);
  const [total_amt, setTotal_amt] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    loadplan(2);
    planDetails(planid);
  }, []);
  const loadplan = async (planType) => {
    try {
      const plan_data = await axios.get(
        api_url + "pricing-plan-listing-app?planType=" + `${planType}`,
        { headers: { Authorization: "close_call_key" } }
      );
      const pricing_plan = JSON.parse(JSON.stringify(plan_data.data));
      if (pricing_plan.status == 1) {
        setPlans(pricing_plan.data.plan_list);
      }
    } catch (e) {
      return null;
    }
  };

  const planDetails = async (plan_id) => {
    try {
      const plan_details = await axios.get(
        api_url + "pricing-plan-details?id=" + `${plan_id}`
      );
      const plan_data = JSON.parse(JSON.stringify(plan_details.data));
      if (plan_data.status == 1) {
        setPlanData(plan_data.data.plan_list);
        setPlanAmt(parseFloat(plan_data.data.plan_list.new_price));
        setDiscount(0);
        setGst(0);
        setServicetax(0);
        const totalamt = parseFloat(plan_data.data.plan_list.new_price);
        setTotal_amt(totalamt);
      }
    } catch (e) {
      return false;
    }
  };

  const handleplanchange = (e) => {
    let id = e.target.value;
    setPlanid(id);
    planDetails(id);
  };

  const Razorpay = useRazorpay();

  const HandleOrder = async ({ total_amt }) => {
    try {
      const data = {
        plan_id: pid,
        company_id: cid,
        mobile:user ? user.mobile : "",
        email:user ? user.email : ""
      };
      const response = await axios.post(
        api_url+'create-order',
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status == 200) {
        const json_data = JSON.parse(JSON.stringify(response.data));

        if(json_data.status==0){
          toast.error(json_data.message);
        }else if(json_data.status==2){
           
            let paymentData = {
                company_id: cid,
                plan_id: pid,
                valid_from:json_data.data.request.today,
                valid_upto:json_data.data.request.valid_upto,
                order_id:"freetrial001",
                transaction_id:"freetrial001",
                amount:json_data.data.request.amount,
                payment_mode:"2",
                status:"2",
              }
           
              const response = await axios.post(
                api_url+"payment-status",
                paymentData,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              const resCity = JSON.parse(JSON.stringify(response.data));

              if (resCity) {
                navigate("/payment-success");
              }
              
            
        }else{
        let orderId = response.data.data.request.order_id;        
        let Amount = response.data.data.request.amount;
        let valid_from = response.data.data.request.today;
        let valid_upto = response.data.data.request.valid_upto;

        const options = {
          key: response.data.data.request.key,
          // amount: response.data.data.request.amount,
          // currency: "INR",
          order_id: response.data.data.request.order_id,
          name: "CloseCall",
          description: "buy plan and start your business in smart way",
          image: "img/close-logo.png",
          handler: async function (res, response) {
            // Check if the payment is successful
            if (res.razorpay_payment_id) {
              const transactionId = res.razorpay_payment_id;
              //  alert("Payment Successful! Transaction ID: " + transactionId);

              const paymentData = {
                company_id: cid,
                plan_id: pid,
                valid_from: valid_from,
                valid_upto:valid_upto,
                order_id: orderId,
                transaction_id: transactionId,
                amount: Amount,
                payment_mode: "2",
                status: "2",
              };

              const response = await axios.post(
                api_url+"payment-status",
                paymentData,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              const resCity = JSON.parse(JSON.stringify(response.data));

              if (resCity) {
                navigate("/payment-success");
              }
              console.log(resCity, "response");
            } else {
              alert("Payment Failed!");
            }
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#0742A6",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      }
    }
    } catch (error) {}
  };

  // const handlePayment = useCallback(
  //   (total_amt) => {
  //     const options: RazorpayOptions = {
  //       key: "rzp_test_HvcXCBcNs4uEN2",
  //       amount: parseFloat(total_amt) * 100,
  //       currency: "INR",
  //       name: "CloseCall",
  //       description: "Close Call plan Buying",
  //       image: "img/close-logo.png",
  //       handler: (res) => {
  //         console.log(res);
  //       },
  //       prefill: {
  //         name: "Piyush Garg",
  //         email: "youremail@example.com",
  //         contact: "9999999999",
  //       },
  //       notes: {
  //         address: "Razorpay Corporate Office",
  //       },
  //       theme: {
  //         color: "#0742A6",
  //       },
  //     };

  //     const rzpay = new Razorpay(options);
  //     rzpay.open();
  //   },
  //   [Razorpay]
  // );
  return (
    <>
    <ToastContainer/>
      <section id="checkout-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
              <div className="checkout-inner-left">
                <h3>Subscription Plan</h3>
                <div className="standard-plan">
                  <div className="form-check-inline">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="optradio"
                        checked
                        value={planData.id}
                      />
                      {planData.title}
                    </label>
                  </div>
                  <p>
                    <img
                      src="img/red-tick.png"
                      className="img-fluid mr-1"
                      width="14px"
                    />
                    ₹{planData.new_price}
                  </p>
                </div>
                <ul>
                  {planData &&
                    planData.plan_features.map((ft, i) => (
                      <>
                        <li>{ft}</li>
                      </>
                    ))}
                </ul>
                <div className="change-plan">
                  <p>If you want to change your plan.</p>
                  <a
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Change Plan
                  </a>
                </div>
                <div className="collapse" id="collapseExample">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => handleplanchange(e)}
                  >
                    {plans &&
                      plans.map((item, index) => (
                        <>
                          {item.id == pid ? (
                            <>
                              <option value={item.id} selected>
                                {item.title}
                              </option>
                            </>
                          ) : (
                            <>
                              <option value={item.upid}>{item.title}</option>
                            </>
                          )}
                        </>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
              <div className="discount-card">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        If you have Coupon Code ?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="discount">
                          <span className="discount-icon">
                            <img
                              src="img/discount-icon.png"
                              width="18px"
                              className="img-fluid me-2"
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="XRTMAS70"
                          />
                          <span className="red-tick">
                            <img
                              src="img/red-tick.png"
                              className="img-fluid"
                              width="14px"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-card">
                <h3>Billing Summary</h3>
                <div className="total">
                  <div>
                    <span>{planData.title}</span>
                  </div>
                  <div>
                    <p>₹{planData.new_price}</p>
                  </div>
                </div>
                <div className="total">
                  <div>
                    <span>Discount</span>
                    <a href="" className="remove">
                      Remove
                    </a>
                  </div>
                  <div>
                    <p>-₹ {discount}</p>
                  </div>
                </div>
                <div className="total">
                  <div>
                    <span>GST</span>
                  </div>
                  <div>
                    <p>₹ {gst}</p>
                  </div>
                </div>
                <div className="total">
                  <div>
                    <span>Services Tax</span>
                  </div>
                  <div>
                    <p>₹ {servicetax}</p>
                  </div>
                </div>
                <div className="grand-total">
                  <div>Grand Total</div>
                  <div>₹ {total_amt}</div>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked
                  />
                  <label className="form-check-label" for="flexCheckChecked">
                    My billing and shipping address are the same
                  </label>
                </div>
                <button
                  type="button"
                  className="btn paynow"
                  onClick={() => HandleOrder(total_amt)}
                >
                  Pay Now
                </button>
                <div className="payment-icon">
                  <div>
                    <img
                      src="img/norton-icon.png"
                      width="76px"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src="img/payment-icon1.png"
                      width="34px"
                      className="img-fluid"
                    />
                    <img
                      src="img/payment-icon2.png"
                      width="34px"
                      className="img-fluid"
                    />
                    <img
                      src="img/payment-icon3.png"
                      width="34px"
                      className="img-fluid"
                    />
                    <img
                      src="img/payment-icon4.png"
                      width="34px"
                      className="img-fluid"
                    />
                    <img
                      src="img/payment-icon5.png"
                      width="34px"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckoutPage;
