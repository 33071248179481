import React, { useState, useEffect, Component } from "react";
import {
  HashRouter,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Index from "./components/pages/index";
import About from "./components/pages/about";
import PrivacyPolicy from "./components/pages/privacypolicy";
import TermCondition from "./components/pages/termcondition";
import RefundPolicy from "./components/pages/refundpolicy";
import Blog from "./components/pages/mainblog";
import PricingPlan from "./components/pages/mainpricing";
import RequestDemo from "./components/pages/requestdemo";
import NotFound from "./components/pages/notfound";
import CheckoutPage from "./components/pages/checkout";
import Signup from "./components/pages/signup";
import ContactUs from "./components/pages/contactus";
import BlogDetail from "./components/pages/blogdetails";
import Profile from "./components/pages/profile";
import PlanDetails from "./components/pages/plan-detail";
import Header from "./components/layouts/header";
import Footer from "./components/layouts/Footer";
import { selectUser } from "./app/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { login, logout, page_id } from "./app/userSlice";
import { selectPath } from "./app/pathSlice";
import CustomPage from "./components/pages/Feature";
import TeleCalling from "./components/pages/teleCalling";
import CallManagementSoftware from "./components/pages/callManagementSoftware";
import Thankyou from "./components/pages/Thankyou";
import Sitemap from "./components/sitemap";
import { web_url, api_url, img_url } from "./components/basic";
import axios from "axios";

function App() {
  const user = useSelector(selectUser);
  const path = useSelector(selectPath);
  const dispatch = useDispatch();
  const [token, setToken] = useState(localStorage.getItem("web_token"));
  const [webRoutes,setWebRoutes]=useState([]);
  useEffect(() => {
    loadRoutes();
    if (localStorage.getItem("web_token") != null) {
      dispatch(
        login({
          dirname: localStorage.getItem("web_dname"),
          token: localStorage.getItem("web_token"),
          cid: localStorage.getItem("cid"),
          mobile: localStorage.getItem("mobile"),
          email: localStorage.getItem("email"),
        })
      );
    } else {
      dispatch(logout());
    }
  }, []);

 const loadRoutes = async () => {
    try {
      const client_data = await axios.get(web_url + "website-routes");
      const client_data_list = JSON.parse(JSON.stringify(client_data.data));
      if (client_data_list.status == 200) {
        setWebRoutes(client_data_list.result);
      }
    } catch (e) {
      return null;
    }
  };

  const routesConfig = {
    routes: [
      { path: "/", element: <Index /> },
      { path: "/about-us", element: <About /> },
      { path: "/blog", element: <Blog /> },
      { path: "/pricing", element: <PricingPlan /> },
      { path: "/request-demo", element: <RequestDemo /> },
      {
        path: "/signup",
        element: <Signup />,
      },
    
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/term-condition", element: <TermCondition /> },
      { path: "/refund-policy", element: <RefundPolicy /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/blog-detail", element: <BlogDetail /> },
      {path:"/sitemap.xml" ,element:<Sitemap/>},  

      { path: "*", element: <NotFound /> },
    ],
    userRoutes: [
      { path: "/profile", element: <Profile /> },
      { path: "/checkout", element: <CheckoutPage /> },
      { path: "/plan-detail", element: <PlanDetails /> },
      {
        path: "/payment-success",
        element: <Thankyou />,
      },
    ],
  };

  // console.log(path.path.split("/")[1]);

  return (
    <>
      <Router>
        {path && path.path.split("/")[1] !== "request-demo" ? <Header /> : null}
        <Routes>
          {routesConfig.routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}/>
          ))}          
          <Route path="/:slug" element={<CustomPage/>}/>         
          {user &&
            routesConfig.userRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
        </Routes>
        {path && path.path.split("/")[1] !== "request-demo" ? <Footer /> : null}
      </Router>
    </>
  );
}

export default App;
