import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/Footer";
import Modal from "../layouts/modal";
import PageCounter from "./counter";
import ClientTestimonials from "./testimonials";
import PagePricing from "./PagePricing";
import PageBlog from "./pageblog";
import PageFAQ from "./pagefaq";
import PageSubscribe from "./pagesubscribe";
import TrustedPartner from "./trusted";
import axios from "axios";
import { api_url, web_url, img_url } from "../basic";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, logout, selectPage_id } from "../../app/userSlice";
import { selectPath, create_path } from "../../app/pathSlice";
const Index = () => {
  const dispatch = useDispatch();
  const [p_id, setP_id] = useState("");
  const [path, setPath] = useState(window.location.pathname);
  const [banner, setBanner] = useState({
    banner_img: "",
    banner_desc: "",
    banner_link: "",
  });
  const [companydata, setCompanydata] = useState({
    app_store_link: "",
    play_store_link: "",
    copy_right: "",
  });
  const { app_store_link, play_store_link, copy_right } = companydata;
  const [content, setContent] = useState("");
  const { banner_img, banner_link, banner_desc } = banner;
  useEffect(() => {
    setPath(window.location.pathname);
    loadPage();
    companydetails();
    dispatch(
      create_path({
        path: window.location.pathname,
      })
    );
  }, []);

  const loadPage = async () => {
    try {
      let pathname = path.split("/");
      const data = await axios.get(
        web_url + "website-page?slug=" + `${pathname[1]}`
      );
      const datalist = JSON.parse(JSON.stringify(data.data));
      if (datalist.status == 200) {
        setP_id(datalist.result.id);
        loadbanner(datalist.result.id);
        loadPageContent(datalist.result.id);
        loadmetatags(datalist.result.id);
      }
    } catch (e) {
      return false;
    }
  };
  const loadbanner = async (p_id) => {
    try {
      const banner_data = await axios.get(
        web_url + "website-banner?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(banner_data.data));
      if (data.status == 200) {
        setBanner({
          ...banner,
          banner_img: data.result.image,
          banner_desc: data.result.description,
          banner_link: data.result.link,
        });
      }
    } catch (e) {
      return false;
    }
  };

  const loadPageContent = async (p_id) => {
    try {
      const page_content = await axios.get(
        web_url + "website-content?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(page_content.data));
      if (data.status == 200) {
        setContent(data.result.page_content);
      }
    } catch (e) {
      return false;
    }
  };

  const loadmetatags = async (p_id) => {
    try {
      const page_seo = await axios.get(
        web_url + "website-seo?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(page_seo.data));
      if (data.status == 200) {
        let fullpath = window.location.href;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", data.result.meta_description);
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", data.result.meta_keywords);
        document.querySelector("title").innerHTML = data.result.meta_title;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", data.result.meta_title);
        document
          .querySelector('meta[property="og:url"]')
          .setAttribute("content", fullpath);
        document
          .querySelector('link[rel="canonical"]')
          .setAttribute("href", fullpath);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute("content", data.result.meta_description);
        document
          .querySelector('meta[property="og:site_name"]')
          .setAttribute("content", "closecall");
        document
          .querySelector('meta[property="og:image"]')
          .setAttribute("content", localStorage.getItem("logo_url"));
      } else {
        const desctiption = "Closecall Real-Time Call Tracking Platform";
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", desctiption);
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", desctiption);
        document.querySelector("title").innerHTML = "closecall";
      }
    } catch (e) {
      const desctiption = "Closecall Real-Time Call Tracking Platform";
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", desctiption);
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", desctiption);
      document.querySelector("title").innerHTML = "closecall";
    }
  };

  const companydetails = async () => {
    try {
      const social_link = await axios.get(web_url + "show-company");
      const data = JSON.parse(JSON.stringify(social_link.data));
      if (data.status == 200) {
        setCompanydata({
          ...companydata,
          app_store_link: data.result.app_store_link,
          play_store_link: data.result.play_store_link,
          copy_right: data.result.copy_right,
        });
      }
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      <section
        id="main-banner"
        style={{ background: `url(${img_url + banner_img})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="banner-text">
                <div dangerouslySetInnerHTML={{ __html: banner_desc }}></div>
                <div className="banner-btn">
                  <Link to={banner_link} className="btn get-started">
                    Get Started for Free
                  </Link>
                  <Link to="/about-us" className="btn learn-more">
                    Learn More
                    <img
                      src="img/arrow-icon.png"
                      className="img-fluid"
                      width="22px"
                      alt="lear more"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>

      <TrustedPartner heading={"20,000+ Companies Trust us"} />
      <section id="platform-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
              <div className="platform-image"></div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
              <div className="platform-content">
                <span>Best App</span>
                <h3>
                  Smart and Easy-to-use App for Call Data Analysis & Performance
                  Improvement
                </h3>
                <p>
                  Install the smart phone handling app and track all marketing,
                  sales, and customer service calls using your phone
                </p>
                <div className="platform-btn">
                  <Link to={app_store_link} target="_blank">
                    <img
                      src="img/apple-icon.png"
                      className="img-fluid"
                      alt="Closecall IOS App"
                    />
                  </Link>
                  <Link to={play_store_link} target="_blank">
                    <img
                      src="img/playstore-icon.png"
                      className="img-fluid"
                      alt="Closecall App"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PageCounter />
      <ClientTestimonials />
      <PagePricing />
      <PageFAQ />
      <PageSubscribe />
    </>
  );
};

export default Index;
