import React from 'react';
const PriceCalcy=({setIsCalcy,IsCalcy})=>{
	const backpage=()=>{
		setIsCalcy(false);
	}
	return (
		<>
		<div className="card">
			<div className="card-body">			
			          <div className="Calculator mt-0">
			          <i className="fa fa-angle-left back-icon me-2" onClick={backpage}></i>
			            <h3>Calculate cost of Close Call for your organisation</h3>
			            <p>Lorem ipsum dolor sit amet consectet adipiscing elit eget quamumto.</p>
			         </div>
			            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
			            <div className="Calculate-main">
			            <div className="Calculate-inner">
			            <div className="purchase-select">
			            <p>Purchase</p>
			            <select className="form-select" aria-label="Default select example">
			            <option selected>Select Your Plan</option>
			            <option value="1">Standard</option>
			            <option value="2">Extended</option>
			            <option value="3">Premium+</option>
			            </select>
			            </div>
			            <div className="licence">
			               <p>Licence For</p>
			               <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="No of Licence"/>
			            </div>
			            </div>
			            <p className="cost">It will cost <span> ₹ 800.00</span> / month / billing annually</p>
			             <a className="btn request-btn" href="request-demo.html">Request A Demo</a>
			          </div>
			    </div>
			    </div>
			
			
			 </div>
		</>
		)
}
export default PriceCalcy;