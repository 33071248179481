import React, { useState, useEffect } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/Footer";
import Modal from "../layouts/modal";
import PageCounter from "./counter";
import PageBlog from "./pageblog";
import PageSubscribe from "./pagesubscribe";
import TrustedPartner from "./trusted";
import axios from "axios";
import { api_url, web_url, img_url } from "../basic";
import { useSelector, useDispatch } from "react-redux";
import { selectPath, create_path } from "../../app/pathSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactUs = () => {
  const dispatch = useDispatch();
  const [p_id, setP_id] = useState("");
  const [path, setPath] = useState(window.location.pathname);
  const [contactDetails, setContactDetails] = useState({
    fullname: "",
    email: "",
    mobile: "",
    msg: "",
  });
  const [fullnameError, setFullnameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [mobileError, setMobileError] = useState(true);
  const [msgError, setMsgError] = useState(true);

  const [banner, setBanner] = useState({
    banner_img: "",
    banner_desc: "",
    banner_link: "",
    banner_title: "",
  });
  const [content, setContent] = useState("");
  const { banner_img, banner_link, banner_desc, banner_title } = banner;
  const { fullname, email, mobile, msg } = contactDetails;
  useEffect(() => {
    setPath(window.location.pathname);
    loadPage();
    dispatch(
      create_path({
        path: window.location.pathname,
      })
    );
  }, []);

  const loadPage = async () => {
    try {
      let pathname = path.split("/");
      const data = await axios.get(
        web_url + "website-page?slug=" + `${pathname[1]}`
      );
      const datalist = JSON.parse(JSON.stringify(data.data));
      if (datalist.status == 200) {
        setP_id(datalist.result.id);
        loadbanner(datalist.result.id);
        loadPageContent(datalist.result.id);
        loadmetatags(datalist.result.id);
      }
    } catch (e) {}
  };
  const loadbanner = async (p_id) => {
    try {
      const banner_data = await axios.get(
        web_url + "website-banner?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(banner_data.data));
      if (data.status == 200) {
        setBanner({
          ...banner,
          banner_img: data.result.image,
          banner_desc: data.result.description,
          banner_link: data.result.link,
        });
      }
    } catch (e) {
      return false;
    }
  };

  const loadPageContent = async (p_id) => {
    try {
      const page_content = await axios.get(
        web_url + "website-content?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(page_content.data));
      if (data.status == 200) {
        setContent(data.result.page_content);
      }
    } catch (e) {
      return false;
    }
  };

  const handlecontactchange = (e) => {
    setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  };

  const ValidateRequest = () => {
    if (fullname === "") {
      setFullnameError(false);
    } else {
      setFullnameError(true);
    }
    if (email === "") {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    if (mobile === "") {
      setMobileError(false);
    } else {
      setMobileError(true);
    }

    if (fullname === "" || email === "" || mobile === "") {
      return false;
    } else {
      return true;
    }
  };

  const handlecontactRequest = async () => {
    if (ValidateRequest() == true) {
      try {
        var pathname = window.location.pathname;
        var form = {
          mobile: mobile,
          name: fullname,
          email: email,
          industry: "0",
          message: msg,
          pathname: pathname,
        };
        const otp_sent = await axios.post(web_url + "send-otp-request", form);
        const otp_Data = JSON.parse(JSON.stringify(otp_sent.data));
        if (otp_Data.status == 200) {
          toast.success("Thank you our team revert you shortly");
        } else {
          toast.error("mobile no does not exist");
        }
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  };

  const loadmetatags = async (p_id) => {
    try {
      const page_seo = await axios.get(
        web_url + "website-seo?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(page_seo.data));
      if (data.status == 200) {
        let fullpath = window.location.href;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", data.result.meta_description);
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", data.result.meta_keywords);
        document.querySelector("title").innerHTML = data.result.meta_title;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", data.result.meta_title);
        document
          .querySelector('meta[property="og:url"]')
          .setAttribute("content", fullpath);
        document
          .querySelector('link[rel="canonical"]')
          .setAttribute("href", fullpath);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute("content", data.result.meta_description);
        document
          .querySelector('meta[property="og:site_name"]')
          .setAttribute("content", "closecall");
        document
          .querySelector('meta[property="og:image"]')
          .setAttribute("content", localStorage.getItem("logo_url"));
      } else {
        const desctiption = "Closecall Real-Time Call Tracking Platform";
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", desctiption);
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", desctiption);
        document.querySelector("title").innerHTML = "closecall";
      }
    } catch (e) {
      const desctiption = "Closecall Real-Time Call Tracking Platform";
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", desctiption);
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", desctiption);
      document.querySelector("title").innerHTML = "closecall";
    }
  };
  return (
    <>
      <ToastContainer />
      <section id="contatc-section">
        <div className="container">
          <div className="contact-heading">
            <h3>Contact Us</h3>
            <div dangerouslySetInnerHTML={{ __html: banner_desc }}></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-1"></div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
              <div className="contact-right">
                <h4>Send Message</h4>
                <div className="mb-3">
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (fullnameError != true ? " error-warning" : "")
                    }
                    placeholder="Full Name"
                    name="fullname"
                    onChange={(e) => handlecontactchange(e)}
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (emailError != true ? " error-warning" : "")
                    }
                    placeholder="Email"
                    name="email"
                    onChange={(e) => handlecontactchange(e)}
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (mobileError != true ? " error-warning" : "")
                    }
                    placeholder="Mobile"
                    name="mobile"
                    onChange={(e) => handlecontactchange(e)}
                  />
                </div>
                <div class="mb-3">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="Type your message"
                    name="msg"
                    onChange={(e) => handlecontactchange(e)}
                  ></textarea>
                </div>
                <button className="btn send" onClick={handlecontactRequest}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TrustedPartner heading={"20,000+ Companies Trust us"} />
    </>
  );
};
export default ContactUs;
