import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../app/userSlice";
import { Link, useNavigate } from "react-router-dom";

const Thankyou = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  return (
    <section class="pt-5 mt-5 pb-5">
      <div class="container">
        <div class="card p-4 w-75 mx-auto">
          <div class="modal-content">
            <div class="modal-body">
              <img src="img/thankyou.gif" width="168px" class="img-fluid" />
              <h2>Thank You</h2>
              <p>
                Hi <span> {user ? user.dirname : ""} </span>, We appreciate your
                decision to purchase our plan. We hope you enjoy exploring its
                features and functions.
              </p>
              <button
                type="button"
                class="btn go-home mb-4"
                onClick={() => {
                  navigate("/");
                }}
              >
                Go to Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Thankyou;
