import React, { useState } from "react";

import Slider from "react-slick";

const GrowImages = () => {
  const [settings2, setSettings2] = useState({
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  const GrowImages = [
    { img: "img/Advertising-&-Media.png", name: "Advertising & Media" },
    { img: "img/Aviation.png", name: "Aviation" },
    { img: "img/Banking-&-Insurance.png", name: "Banking & Insurance" },
    { img: "img/E-commerce.png", name: "E-commerce" },
    { img: "img/Education.png", name: "Education" },
    { img: "img/Energy.png", name: "Energy" },
    { img: "img/Finance.png", name: "Finance" },
    { img: "img/fmcg.png", name: "FMGC" },
    { img: "img/Healthcare.png", name: "Healthcare" },
    { img: "img/Hotels.png", name: "Hotels" },
    { img: "img/Information-Technology.png", name: "Information Technology" },
    { img: "img/logistics.png", name: "Logistics" },
    { img: "img/Real-Estate.png", name: "Real-Estate" },
    { img: "img/Salons-&-Spa.png", name: "Salons & Spa" },
    { img: "img/Textile.png", name: "Textile" },
    { img: "img/Tourism.png", name: "Tourism" },
  ];

  return (
    <Slider {...settings2}>
      {GrowImages &&
        GrowImages.map((item) => (
          <>
            <div className="item">
              <div className="follow-up-card">
                <img
                  src={item.img}
                  className="img-fluid"
                  alt={item.name}
                  width="30px"
                />
                <h5> {item.name}</h5>
              </div>
            </div>
          </>
        ))}
    </Slider>
  );
};

export default GrowImages;
