import { createSlice } from '@reduxjs/toolkit'

export const pricingSlice = createSlice({
    name:"plan",
    initialState:{
      plan:null,     
    },
    reducers:{
      create_plan:(state,action)=>{
        state.plan=action.payload;
      },
    
    },
});

export const { create_plan } = pricingSlice.actions;
export const selectPlan=(state)=>state.plan.plan;
export default pricingSlice.reducer;