import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PageSubscribe from "./pagesubscribe";
import axios from "axios";
import { api_url, web_url, img_url } from "../basic";
import { useSelector, useDispatch } from "react-redux";
import { selectPath, create_path } from "../../app/pathSlice";
import { Link,useParams,useNavigate } from "react-router-dom";
import TrustedPartner from "./trusted";
import GrowImages from "./GrowImages";
import PageFAQ from "./pagefaq";


const CustomPage = () => {
  let { slug } = useParams();
   const history = useNavigate();
  const [companydata, setCompanydata] = useState({
    app_store_link: "",
    play_store_link: "",
    copy_right: "",
  });
  const { app_store_link, play_store_link, copy_right } = companydata;

  useEffect(() => {
    async function loadDataAndInitializeScrollSpy() {
      await loadPage();

      setTimeout(() => {
        const scrollspy = new window.bootstrap.ScrollSpy(document.body, {
          target: "#simple-list-example",
        });

        return () => {
          scrollspy.dispose();
        };
      }, 2000);
    }

    loadDataAndInitializeScrollSpy();
    
  }, [history]);

  const dispatch = useDispatch();
  const [p_id, setP_id] = useState("");
  const [path, setPath] = useState(window.location.pathname);
  const [banner, setBanner] = useState({
    banner_img: "",
    banner_desc: "",
    banner_link: "",
    banner_title: "",
  });
  const [content, setContent] = useState("");
  const { banner_img, banner_link, banner_desc } = banner;
  useEffect(() => {
    setPath(window.location.pathname);
    loadPage();
    dispatch(
      create_path({
        path: window.location.pathname,
      })
    );
  }, []);

  const loadPage = async () => {
    try {
      let pathname = path.split("/");
      const data = await axios.get(
        web_url + "website-page?slug=" + `${slug}`
      );
      const datalist = JSON.parse(JSON.stringify(data.data));
      if (datalist.status == 200) {
        setP_id(datalist.result.id);
        loadbanner(datalist.result.id);
        loadPageContent(datalist.result.id);
        loadmetatags(datalist.result.id);
      }
    } catch (e) {}
  };
  const loadbanner = async (p_id) => {
    try {
      const banner_data = await axios.get(
        web_url + "website-banner?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(banner_data.data));
      if (data.status == 200) {
        setBanner({
          ...banner,
          banner_img: data.result.image,
          banner_desc: data.result.description,
          banner_link: data.result.link,
        });
      }
    } catch (e) {
      return false;
    }
  };

  const loadPageContent = async (p_id) => {
    try {
      const page_content = await axios.get(
        web_url + "website-content?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(page_content.data));
      if (data.status == 200) {
        setContent(data.result.page_content);
      }
    } catch (e) {
      return false;
    }
  };

  const loadmetatags = async (p_id) => {
    try {
      const page_seo = await axios.get(
        web_url + "website-seo?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(page_seo.data));
      if (data.status == 200) {
        let fullpath = window.location.href;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", data.result.meta_description);
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", data.result.meta_keywords);
        document.querySelector("title").innerHTML = data.result.meta_title;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", data.result.meta_title);
        document
          .querySelector('meta[property="og:url"]')
          .setAttribute("content", fullpath);
        document
          .querySelector('link[rel="canonical"]')
          .setAttribute("href", fullpath);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute("content", data.result.meta_description);
        document
          .querySelector('meta[property="og:site_name"]')
          .setAttribute("content", "closecall");
        document
          .querySelector('meta[property="og:image"]')
          .setAttribute("content", localStorage.getItem("logo_url"));
      } else {
        const desctiption = "Closecall Real-Time Call Tracking Platform";
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", desctiption);
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", desctiption);
        document.querySelector("title").innerHTML = "closecall";
      }
    } catch (e) {
      const desctiption = "Closecall Real-Time Call Tracking Platform";
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", desctiption);
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", desctiption);
      document.querySelector("title").innerHTML = "closecall";
    }
  };

  console.log("test");

  return (
    <>
      {/* <div>
        <section id="features-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                <div className="features-left-side">
                  <h1>Boost your Sales with Call Tracking Software</h1>
                  <p>
                    Empower your business with our call tracking magic and boost
                    the calling productivity and efficiency of your employees.
                  </p>
                  <button type="button" className="btn sign-up">
                    Get Started <i className="fa fa-long-arrow-right ms-2"></i>
                  </button>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <h5 className="text-info">
                        <i className="fa fa-clock-o"></i> 2400+
                      </h5>
                      <h6>Hours Tracked</h6>
                    </div>
                    <div>
                      <h5 className="text-warning">
                        <i className="fa fa-star"></i> 99.7%
                      </h5>
                      <h6>Effective</h6>
                    </div>
                    <div>
                      <h5 className="text-success">
                        <i className="fa fa-level-up"></i> 10X
                      </h5>
                      <h6>Boost in Revenue</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div className="features-right-side">
                  <img src="img/call-img.png" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="growth">
          <div className="container-fluid">
            <div className="growth-heading">
              <h2>What is Call Tracking Software?</h2>
              <p>
                Call tracking software is a form of intelligent call monitoring
                software that tracks all incoming and outgoing calls in real
                time. It allows business ventures to monitor the performance of
                calling team members and find out the scope of improvement. It
                is generally used by the sales and customer service departments
                of an organization. By using this software, an organization can
                identify the non-performing resources, make strategies to
                improve their performance and eliminate the lazy resources that
                are not willing to upgrade themselves.
              </p>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="growth-btn">
                  <p>Unlimited Call Recordings</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="growth-btn">
                  <p>Calling Performance Analytics</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="growth-btn">
                  <p>Interactive Call History</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="growth-btn">
                  <p>Live Status of Team</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="boost-section">
          <div className="container-fluid">
            <div className="boost-heading">
              <h2>What are the Advantages of Call Tracking Software?</h2>
              <p>
                The call tracking software provides a wide range of benefits to
                businesses. The significant advantages of call tracking software
                are mentioned below-
              </p>
            </div>
            <div className="row" id="boost-accordion">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Customer Insights
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Call tracking software will equip your business with
                        knowledge of customer behaviour and preferences during
                        interaction on phone calls. You will come to know about
                        the requirements of customers by analyzing the call
                        recordings and making a strategy pertaining to how to
                        convince the customers to buy the product or take the
                        service.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/live-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Employees Performance Evaluation
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        By installing the Closecall app on the phones of your
                        resources, you can evaluate their performance and
                        ascertain ways to upgrade their performance and make
                        them productive.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/about-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Efficient Allocation of Resources
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        If you are a business owner, it will help you get an
                        in-depth overview of all the calling efforts made by
                        your calling executives and representatives. Thus, it
                        will help you proceed with the efficient allocation of
                        resources.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/accessible-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Competitive Edge
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        By integrating Closecall into your business model, you
                        will get a competitive edge over your business rivals.
                        There will be increased scope for bringing improvement
                        and transforming your business.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/accessible-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        Differentiating between the Star Performers and
                        Non-performers
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        As you can analyze the individual performance of all the
                        employees that are involved in calling work, you can
                        easily differentiate between the top performers and the
                        non-performers.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/accessible-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        Boosts Conversion Rate
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        By implementing the call tracking software model into
                        your business venture, you will witness a substantial
                        boost in your sales conversion rate.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/accessible-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Data-driven Decisions
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Our call tracking software will enable your business
                        venture to make informed decisions based on a real-time
                        call tracking system.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/accessible-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        Refines Sale Processes
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Analyzing calls assists in refining the sales processes
                        and growing the revenue of your business. It helps in
                        digging out the flaws and making the required changes
                        for better sales conditions in the future.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/accessible-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        Compliance and Training
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Recordings ensure compliance with the regulations of the
                        organization. Also, recordings are the way through which
                        the current performance level of the employees can be
                        measured. On the basis of the current position, the
                        right training can be provided to the employees.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/accessible-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEleven"
                        aria-expanded="false"
                        aria-controls="collapseEleven"
                      >
                        Real-time Sync
                      </button>
                    </h2>
                    <div
                      id="collapseEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEleven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        This feature of the Closecall call tracking software
                        ensures instant synchronization of all recordings and
                        call data. The real-time sync feature will give you a
                        real-time update about your employees.
                      </div>
                      <div className="call-analytics-img">
                        <img
                          src="img/accessible-img.png"
                          className="img-fluid"
                          alt="Call Monitoring"
                          width="763"
                          height="485"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7"></div>
            </div>
          </div>
        </section>

        <section id="growth">
          <div className="container-fluid">
            <div className="growth-heading">
              <h2>What is Call Tracking Software?</h2>
              <p>
                The productive conversations will eventually lead to powerful
                sales conversion for your business venture.
              </p>
              <p>
                It’s time to make your conversations productive by finding out
                the loopholes in the calling activities and improving the
                performance of your employees. Analyze the call history of your
                calling team members and create the scope for improvement with
                India’s best call tracking software.
              </p>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="growth-btn">
                  <p>Train Non-performers</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="growth-btn">
                  <p>Identify Shining Stars</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="growth-btn">
                  <p>Instant Call Log Review</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="growth-btn">
                  <p>Increase Productivity</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="Expose">
          <div className="container-fluid">
            <div className="growth-heading">
              <h2>
                Expose the Poor Performers- Lack of Follow-ups, Unattended Calls
              </h2>
              <p>
                Conduct proper research on the efforts of your team members,
                find out the gap and reasons behind low sales volume, and
                ascertain ways to enhance sales numbers.
              </p>
              <p>
                What’s the reason behind the inconsistent growth of your
                business?- Lack of follow-ups or a substantial number of calls
                remain unattended or negligence of team members.
              </p>
              <p>
                Get an in-depth overview of the total number of incoming,
                outgoing, rejected and never attended calls, and work on
                enhancing the capacity of your team members using our call
                tracking software.
              </p>
            </div>
          </div>
        </section>

        <section id="platform-section">
          <div classNameName="container-fluid">
            <div classNameName="row">
              <div classNameName="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                <div classNameName="platform-image"></div>
              </div>
              <div classNameName="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div classNameName="platform-content">
                  <span>Best App</span>
                  <h3>
                    Smart and Easy-to-use App for Call Data Analysis &
                    Performance Improvement
                  </h3>
                  <p>
                    Install the smart phone handling app and track all
                    marketing, sales, and customer service calls using your
                    phone
                  </p>
                  <div classNameName="platform-btn">
                    <Link to={app_store_link} target="_blank">
                      <img
                        src="img/apple-icon.png"
                        classNameName="img-fluid"
                        alt="Closecall IOS App"
                      />
                    </Link>
                    <Link to={play_store_link} target="_blank">
                      <img
                        src="img/playstore-icon.png"
                        classNameName="img-fluid"
                        alt="Closecall App"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="ufeature">
          <div className="container-fluid">
            <div className="col-lg-9 ufeature-heading mx-auto">
              <h2>
                Robust Call Tracking Software with a Dashboard &amp; Features
                that Meet the Needs of Modern-day Businesses
              </h2>
              <p>
                Gain a deeper and better understanding of customer interactions
                with your calling team members. Find out the weak spots in your
                employees and train as well as upgrade them to satisfy the
                customers on call and get the conversion done.
              </p>
              <p>
                Closecall is India&rsquo;s No. 1 call tracking software that
                saves time and money. It hardly takes 5 minutes for you to
                install this app on your phone and leverage the merits of the
                smart call management revolution.
              </p>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div
                  id="simple-list-example"
                  className="d-flex flex-column gap-2 simple-list-example-scrollspy text-center"
                >
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-1">
                      Call Analysis and Data
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-2">
                      Real-time Analytics
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-3">
                      Central Dashboard
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-4">
                      Accessible Call History
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-5">
                      Live Monitoring
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-6">
                      Integration Capabilities
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-7">
                      Click to Call Function
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-8">
                      Data Security
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-9">
                      API Integration
                    </a>
                  </p>
                  <p>
                    <a className="p-1 rounded" href="#simple-list-item-10">
                      Call Recording Sync
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <div
                  className="scrollspy-example"
                  tabindex="0"
                  data-bs-spy="scroll"
                  data-bs-target="#simple-list-example"
                  data-bs-offset="0"
                  data-bs-smooth-scroll="true"
                >
                  <div id="simple-list-item-1" className="feature-list">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Call Analysis and Data</h3>
                          <p>
                            Looking for real-time analysis of your dialled and
                            received calls and eyeing the ways to boost your
                            sales?
                          </p>
                          <p>
                            Well, you are at the right place. With Closecall,
                            you can track incoming and outgoing calls, dialled
                            and received calls, average time per call, number of
                            missed calls, and so on.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-2" className="feature-list flex-dr">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Real-time Analytics</h3>
                          <p>
                            Monitor call volumes, stay on top of your call
                            metrics, and embrace your strategies quickly to
                            seize opportunities.
                          </p>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
              <section id="platform-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
              <div className="platform-image"></div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
              <div className="platform-content">
                <span>Best App</span>
                <h3>
                  Smart and Easy-to-use App for Call Data Analysis & Performance
                  Improvement
                </h3>
                <p>
                  Install the smart phone handling app and track all marketing,
                  sales, and customer service calls using your phone
                </p>
                <div className="platform-btn">
                  <Link to={app_store_link} target="_blank">
                    <img
                      src="img/apple-icon.png"
                      className="img-fluid"
                      alt="Closecall IOS App"
                    />
                  </Link>
                  <Link to={play_store_link} target="_blank">
                    <img
                      src="img/playstore-icon.png"
                      className="img-fluid"
                      alt="Closecall App"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>             <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-3" className="feature-list">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Central Dashboard</h3>
                          <p>
                            A powerful dashboard that exhibits incoming and
                            outgoing call duration, working hours, call logs and
                            much more.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-4" className="feature-list flex-dr">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Accessible Call History</h3>
                          <p>
                            Get the summarized report of call history that
                            includes incoming, outgoing, missed, and rejected
                            calls and unique clients.
                          </p>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-5" className="feature-list">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Live Monitoring</h3>
                          <p>
                            The live monitoring feature is an effective way to
                            track working hours spent on every project, and it
                            shows idle and ongoing calls.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-6" className="feature-list flex-dr">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Integration Capabilities</h3>
                          <p>
                            Integrate call tracking software and perform your
                            tasks seamlessly with your existing tools for a
                            unified workflow.
                          </p>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-7" className="feature-list">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Click to Call Function</h3>
                          <p>
                            Click to Call function is a smart intelligent Call
                            Feature that allows your employees to make a call
                            from CRM.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-8" className="feature-list flex-dr">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Data Security</h3>
                          <p>
                            We focus on the security of your sensitive
                            information and data. Furthermore, we employ the
                            latest encryption measures.
                          </p>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-9" className="feature-list">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>API Integration</h3>
                          <p>
                            Our API integration ensures seamless reporting as
                            well as management. It&rsquo;s time to connect to
                            your existing CRM.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="simple-list-item-10" className="feature-list flex-dr">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                        <div className="feature-content-part">
                          <h3>Call Recording Sync</h3>
                          <p>
                            Keep track of all incoming as well as outgoing call
                            recordings in real time. You can monitor the calls
                            and recordings easily.
                          </p>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="feature-img-part">
                          <img className="img-fluid" src="img/call-img.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="close-work">
          <div className="container-fluid">
            <div className="close-work-heading">
              <h2>How does Closecall work?</h2>
              <p>Follow the four steps and start using Closecall.</p>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="close-work-card">
                  <div className="close-img-back">
                    <img
                      src="img/close-work-img-1.png"
                      className="img-fluid"
                      width="40px"
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="steps">1</div>
                    <span>Open Playstore</span>
                  </div>
                  <p>
                    To install the Closecall app, open the Google Play store on
                    employees' phones.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="close-work-card">
                  <div className="close-img-back">
                    <img
                      src="img/close-work-img-2.png"
                      className="img-fluid"
                      width="40px"
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="steps">2</div>
                    <span>Install Closecall App</span>
                  </div>
                  <p>
                    Search the closecall app on the Play Store, and download and
                    install it.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="close-work-card">
                  <div className="close-img-back">
                    <img
                      src="img/close-work-img-3.png"
                      className="img-fluid"
                      width="40px"
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="steps">3</div>
                    <span>Automatic Syncing</span>
                  </div>
                  <p>
                    Automatic syncing of calling data will take place in
                    real-time.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                <div className="close-work-card">
                  <div className="close-img-back">
                    <img
                      src="img/close-work-img-4.png"
                      className="img-fluid"
                      width="40px"
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="steps">4</div>
                    <span>Access the Dashboard</span>
                  </div>
                  <p>
                    Access the dashboard and get in-depth calling reports and
                    stats.simple-list-example
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */}
      <div dangerouslySetInnerHTML={{ __html: content }}></div>

      <section id="follow-up">
        <div className="container-fluid">
          <div className="follow-up-heading col-lg-8 mx-auto">
            <h2>
              A Tool for Businesses that Ensures Efficient Follow-ups & 100%
              Boost in Sales
            </h2>
            <p>
              Closecall is a call tracking software that improves the calling
              performance, manages workload, and take sales number to new
              heights. The industries and businesses that need Closecall are:
            </p>
          </div>
          <div className="row" id="follow-slider">
            <GrowImages />
          </div>
        </div>
      </section>

      <section id="trusted-companies">
        <TrustedPartner
          heading={
            "First Choice of Unicorn Startups, Small Firms, and Sales Teams"
          }
        />
      </section>
      <PageFAQ pageid={p_id} />

      <section id="schedule">
        <div className="container-fluid">
          <div className="schedule-main">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <div className="schedule-text">
                  <h4>Improve your Business Revenue with Us</h4>
                  <p>
                    Try India’s no. 1 call tracking software and Take Business
                    to New Heights
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                <div className="schedule-button">
                  <button type="button" className="btn schedule">
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PageSubscribe />
    </>
  );
};

export default CustomPage;
