import React, { useState, useEffect } from "react";
import { web_url, api_url, img_url } from "../basic";
import axios from "axios";
import Slider from "react-slick";
const TrustedPartner = ({ heading }) => {
  const [client, setClient] = useState([]);
  const [settings, setSettings] = useState({
    dots: false,
    loop: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  useEffect(() => {
    loadclient();
  }, []);
  const loadclient = async () => {
    try {
      const client_data = await axios.get(web_url + "list-web-client");
      const client_data_list = JSON.parse(JSON.stringify(client_data.data));
      if (client_data_list.status == 200) {
        setClient(client_data_list.result);
      }
    } catch (e) {
      return null;
    }
  };

  return (
    <>
      <section id="trusted-companies">
        <div className="container-fluid">
          <div className="companies-heading">
            <h5>{heading}</h5>
          </div>
          <div className="justify-content-between align-items-center">
            <div
              className="row align-items-center companies-logo"
              id="companies_logo"
            >
              <Slider {...settings}>
                {client &&
                  client.map((item) => (
                    <>
                      <div className="item">
                        <img
                          src={img_url + item.logo}
                          className="img-fluid"
                          alt={item.client_name}
                        />
                      </div>
                    </>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TrustedPartner;
