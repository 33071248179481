import React, { useState, useEffect } from "react";
import { web_url, api_url, img_url } from "../basic";
import axios from "axios";
const PageFAQ = ({ pageid }) => {
  const [faqlist, setFaqlist] = useState([]);
  useEffect(() => {
    loadfaq();
  }, []);
  const loadfaq = async () => {
    try {
      const faq_list = await axios.get(
        web_url + "website-faq?page_id=" + `${pageid}`
      );
      const data = JSON.parse(JSON.stringify(faq_list.data));
      if (data.status == 200) {
        setFaqlist(data.result);
      }
    } catch (e) {
      return false;
    }
  };
  return (
    <>
      <section id="faq-section">
        <div className="container">
          <div className="faq-heading">
            <h3>Frequently Asked Questions</h3>
          </div>
          <div className="row">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {faqlist.map((item, index) => (
                <>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id={"flush-headingTwo" + (index + 1)}
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#flush-collapseTwo" + (index + 1)}
                        aria-expanded="false"
                        aria-controls={"flush-collapseTwo" + (index + 1)}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={"flush-collapseTwo" + (index + 1)}
                      className="accordion-collapse collapse"
                      aria-labelledby={"flush-headingTwo" + (index + 1)}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      ></div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PageFAQ;
