import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/Footer";
import Modal from "../layouts/modal";
import PagePricing from "./PagePricing";
import { useSelector, useDispatch } from "react-redux";
import { selectPlan } from "../../app/pricingSlice";
import {selectUser} from '../../app/userSlice';
import axios from "axios";
import { api_url, web_url, img_url } from "../basic";
import { selectPath, create_path } from "../../app/pathSlice";
const PricingPlan = () => {
  const user = useSelector(selectUser);
  const plan = useSelector(selectPlan);
  const [totalCast, setTotalCast] = useState(0);
  const [minDevice, setMinDevice] = useState(5);
  const [freeTrial,setFreeTrial]=useState({});
  const [price, setPrice] = useState(0);
  const [plans, setPlans] = useState(
    plan != null ? (plan.plan.length > 0 ? plan.plan : null) : null
  );
  const dispatch = useDispatch();
  const [p_id, setP_id] = useState("");
  const [path, setPath] = useState(window.location.pathname);
  const [banner, setBanner] = useState({
    banner_img: "",
    banner_desc: "",
    banner_link: "",
    banner_title: "",
  });
  const { banner_img, banner_link, banner_desc } = banner;
  const [content, setContent] = useState("");
  useEffect(() => {
    setPlans(plan?.plan);
    setPath(window.location.pathname);
    loadPage();
    dispatch(
      create_path({
        path: window.location.pathname,
      })
    );
    freeTrialList();
  }, [plan?.plan]);

  const handleChoosePlan = (e) => {
    let id = e.target.value;
    if (id != 0) {
      planDetails(id);
    } else {
      return false;
    }
  };

  const handleDevice = (e) => {
    let device = e.target.value;
    setMinDevice(device);
    setTotalCast(price * device);
  };

  const loadPage = async () => {
    try {
      let pathname = path.split("/");
      const data = await axios.get(
        web_url + "website-page?slug=" + `${pathname[1]}`
      );
      const datalist = JSON.parse(JSON.stringify(data.data));
      if (datalist.status == 200) {
        setP_id(datalist.result.id);
        loadbanner(datalist.result.id);
        loadPageContent(datalist.result.id);
        loadmetatags(datalist.result.id);
      }
    } catch (e) {}
  };

  const loadbanner = async (p_id) => {
    try {
      const banner_data = await axios.get(
        web_url + "website-banner?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(banner_data.data));
      if (data.status == 200) {
        setBanner({
          ...banner,
          banner_img: data.result.image,
          banner_desc: data.result.description,
          banner_link: data.result.link,
        });
      }
    } catch (e) {
      return false;
    }
  };

  const loadPageContent = async (p_id) => {
    try {
      const page_content = await axios.get(
        web_url + "website-content?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(page_content.data));
      if (data.status == 200) {
        setContent(data.result.page_content);
      }
    } catch (e) {
      return false;
    }
  };

  const planDetails = async (plan_id) => {
    try {
      const plan_details = await axios.get(
        api_url + "pricing-plan-details?id=" + `${plan_id}`
      );
      const plan_data = JSON.parse(JSON.stringify(plan_details.data));
      if (plan_data.status == 1) {
        setPrice(plan_data.data.plan_list.new_price);
        const total_price = setTotalCast(
          plan_data.data.plan_list.new_price * minDevice
        );
      }
    } catch (e) {
      return false;
    }
  };

  /*--------------free trial listing apis-----------*/
const freeTrialList = async () => {
    try {
      const plan_details = await axios.get(
        api_url + "free-trial-plan"
      );
      const plan_data = JSON.parse(JSON.stringify(plan_details.data));
      if (plan_data.status == 1) {
        setFreeTrial(plan_data.data.plan_list);        
      }
    } catch (e) {
      return false;
    }
  };

  /*-----------handle login--------*/

const handleLogin=()=>{
    document.getElementById('LoginBtn').click();
  }

  const loadmetatags = async (p_id) => {
    try {
      const page_seo = await axios.get(
        web_url + "website-seo?page_id=" + `${p_id}`
      );
      const data = JSON.parse(JSON.stringify(page_seo.data));
      if (data.status == 200) {
        let fullpath = window.location.href;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", data.result.meta_description);
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", data.result.meta_keywords);
        document.querySelector("title").innerHTML = data.result.meta_title;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", data.result.meta_title);
        document
          .querySelector('meta[property="og:url"]')
          .setAttribute("content", fullpath);
        document
          .querySelector('link[rel="canonical"]')
          .setAttribute("href", fullpath);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute("content", data.result.meta_description);
        document
          .querySelector('meta[property="og:site_name"]')
          .setAttribute("content", "closecall");
        document
          .querySelector('meta[property="og:image"]')
          .setAttribute("content", localStorage.getItem("logo_url"));
      } else {
        const desctiption = "Closecall Real-Time Call Tracking Platform";
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", desctiption);
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", desctiption);
        document.querySelector("title").innerHTML = "closecall";
      }
    } catch (e) {
      const desctiption = "Closecall Real-Time Call Tracking Platform";
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", desctiption);
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", desctiption);
      document.querySelector("title").innerHTML = "closecall";
    }
  };
  return (
    <>
      <section id="blog-banner">
        <div className="container-fluid">
          <div
            className="blog-back"
            style={{ background: `url(${img_url + banner_img})` }}
          >
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="blog-banner-heading">
                  <h2>Plan Pricing</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PagePricing />

      <section id="schedule">
        <div className="container-fluid">
          <div className="schedule-main">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <div className="schedule-text">
                  <h4>{freeTrial && freeTrial.title}</h4>
                  <p>
                    {freeTrial && freeTrial.description}
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                <div className="schedule-button">
                  <Link to={freeTrial && user?"/checkout?pid="+freeTrial.upid+"&cid="+user.cid:null} onClick={user==null?handleLogin:null}> <button className="btn plan schedule" data={freeTrial && freeTrial.id}>Get Start for Free</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="Calculator">
              <h3>Calculate cost of Close Call for your organisation</h3>
              <p>
                Calculate cost of Close Call for your organisation
              </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
              <div className="Calculate-main">
                <div className="Calculate-inner">
                  <div className="purchase-select">
                    <p>Purchase</p>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => handleChoosePlan(e)}
                    >
                      <option value="0">Select Plan</option>
                      {plans &&
                        plans.map((item, index) => (
                          <>
                            <option value={item.id}>{item.title}</option>
                          </>
                        ))}
                    </select>
                  </div>
                  <div className="licence">
                    <p>Licence For Phone Numbers</p>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="No of Licence"
                      onChange={(e) => handleDevice(e)}
                      value={minDevice}
                    />
                  </div>
                </div>
                <p className="cost">
                  It will cost <span> ₹ {totalCast}</span> / month / billing
                  annually
                </p>
                <Link className="btn request-btn" to="/request-demo">
                  Request A Demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PricingPlan;
