import React,{useState,useEffect} from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/Footer';
import Modal from '../layouts/modal';
import PageCounter from './counter';
import PageBlog from './pageblog';
import PageSubscribe from './pagesubscribe';
import axios from 'axios';
import {api_url,web_url,img_url} from '../basic';
import { useSelector, useDispatch } from 'react-redux';
import {selectPath,create_path} from '../../app/pathSlice';
const RefundPolicy=()=>{
	const dispatch = useDispatch();	
	const [p_id,setP_id]=useState('');
	const [path,setPath]=useState(window.location.pathname);
	const [banner,setBanner]=useState({
		banner_img:'',
		banner_desc:'',
		banner_link:'',
		banner_title:'',
	});
	const [content,setContent]=useState('');
	const {banner_img,banner_link,banner_desc}=banner;
	useEffect(()=>{	
	setPath(window.location.pathname);		
		loadPage();
	dispatch(create_path({
		path:window.location.pathname
	}))
	},[])	

	const loadPage = async()=>{
		try{
			let pathname = path.split('/');
			const data=await axios.get(web_url+'website-page?slug='+`${pathname[1]}`);
			const datalist=JSON.parse(JSON.stringify(data.data));
			if(datalist.status==200){				
				setP_id(datalist.result.id);				
				loadbanner(datalist.result.id);
				loadPageContent(datalist.result.id);
				loadmetatags(datalist.result.id);
			}
		}catch(e){

		}
	}
	const loadbanner=async(p_id)=>{
		try{			
			const banner_data = await axios.get(web_url+'website-banner?page_id='+`${p_id}`);
			const data = JSON.parse(JSON.stringify(banner_data.data));
			if(data.status==200){
				setBanner({...banner,banner_img:data.result.image,banner_desc:data.result.description,banner_link:data.result.link});
			}
		}catch(e){
			return false;
		}
	}

	const loadPageContent=async(p_id)=>{
		try{
			const page_content=await axios.get(web_url+'website-content?page_id='+`${p_id}`);
			const data = JSON.parse(JSON.stringify(page_content.data));
			if(data.status==200){
				setContent(data.result.page_content);
			}
		}catch(e){
			return false;
		}
	}


	const loadmetatags=async(p_id)=>{
		try{
			const page_seo = await axios.get(web_url+'website-seo?page_id='+`${p_id}`);
			const data = JSON.parse(JSON.stringify(page_seo.data));
			if(data.status==200){
				let fullpath = window.location.href;
				document.querySelector('meta[name="description"]').setAttribute('content', data.result.meta_description);
				document.querySelector('meta[name="keywords"]').setAttribute('content', data.result.meta_keywords);
				document.querySelector('title').innerHTML=data.result.meta_title;
				document.querySelector('meta[property="og:title"]').setAttribute('content', data.result.meta_title);
				document.querySelector('meta[property="og:url"]').setAttribute('content', fullpath);
				document.querySelector('link[rel="canonical"]').setAttribute('href', fullpath);
				document.querySelector('meta[property="og:description"]').setAttribute('content', data.result.meta_description);
				document.querySelector('meta[property="og:site_name"]').setAttribute('content', 'closecall');
				document.querySelector('meta[property="og:image"]').setAttribute('content', localStorage.getItem('logo_url'));
			}else{
				const desctiption="Closecall Real-Time Call Tracking Platform";
				document.querySelector('meta[name="description"]').setAttribute('content', desctiption);
				document.querySelector('meta[name="keywords"]').setAttribute('content', desctiption);
				document.querySelector('title').innerHTML="closecall";
			}

		}catch(e){
			const desctiption="Closecall Real-Time Call Tracking Platform";
			document.querySelector('meta[name="description"]').setAttribute('content', desctiption);
			document.querySelector('meta[name="keywords"]').setAttribute('content', desctiption);
			document.querySelector('title').innerHTML="closecall";
		}
		
	}
	return (
			<>
				
					  <section id="blog-banner">
				         <div className="container-fluid">
				            <div className="blog-back" style={{"background":`url(${img_url+banner_img})`}}>
				            <div className="row">
				               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				                  <div className="blog-banner-heading">
				                     <h2>Refund Policy</h2>
				                  </div>
				               </div>
				            </div>
				         </div>
				         </div>
				      </section>
				       <div dangerouslySetInnerHTML={{__html: content}}></div>
				      
			
			</>
		);
}
export default RefundPolicy;

