import React,{useState,useEffect} from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/Footer';
import Modal from '../layouts/modal';
import PageCounter from './counter';
import PageBlog from './pageblog';
import PageSubscribe from './pagesubscribe';
import axios from 'axios';
import {api_url,web_url,img_url} from '../basic';
const BlogDetail=()=>{
	const [p_id,setP_id]=useState('');
	const [path,setPath]=useState(window.location.pathname);
	const [banner,setBanner]=useState({
		banner_img:'',
		banner_desc:'',
		banner_link:'',
		banner_title:'',
	});
	const [content,setContent]=useState('');
	const {banner_img,banner_link,banner_desc}=banner;
	useEffect(()=>{	
	setPath(window.location.pathname);		
		loadPage();
	},[])	

	const loadPage = async()=>{
		try{
			let pathname = path.split('/');
			const data=await axios.get(web_url+'website-page?slug='+`${pathname[1]}`);
			const datalist=JSON.parse(JSON.stringify(data.data));
			if(datalist.status==200){				
				setP_id(datalist.result.id);				
				loadbanner(datalist.result.id);
				loadPageContent(datalist.result.id);
			}
		}catch(e){

		}
	}
	const loadbanner=async(p_id)=>{
		try{			
			const banner_data = await axios.get(web_url+'website-banner?page_id='+`${p_id}`);
			const data = JSON.parse(JSON.stringify(banner_data.data));
			if(data.status==200){
				setBanner({...banner,banner_img:data.result.image,banner_desc:data.result.description,banner_link:data.result.link});
			}
		}catch(e){
			return false;
		}
	}

	const loadPageContent=async(p_id)=>{
		try{
			const page_content=await axios.get(web_url+'website-content?page_id='+`${p_id}`);
			const data = JSON.parse(JSON.stringify(page_content.data));
			if(data.status==200){
				setContent(data.result.page_content);
			}
		}catch(e){
			return false;
		}
	}
	return (
			<>
				<Header/>
					  <section id="blog-details">
				         <div class="container-fluid">
				            <div class="row">
				               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				                  <div class="blog-details">
				                    <span class="tools">Design tools</span><span>March 22, 2023</span>
				                    <h2>Probate, Succession Certificate, & Legal Heir Certificate.</h2>
				                    <img src="img/blog-detail-banner.png" class="img-fluid"/>
				                  </div>
				               </div>
				            </div>
				         </div>     
				         <div class="container">
				          <div class="row">
				            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				              <div class="blog-content-inner">
				                    <p>Design comps, layouts, wireframes—will your clients accept that you go about things the facile way? Authorities in our business will tell in no uncertain terms that Lorem Ipsum is that huge, huge no no to forswear forever.</p>
				                    <p>Not so fast, I'd say, there are some redeeming factors in favor of greeking text, as its use is merely the symptom of a worse problem to take into consideration.</p>
				                    <p>The toppings you may chose for that TV dinner pizza slice when you forgot to shop for foods, the paint you may slap on your face to impress the new boss is your business. But what about your daily bread?</p>
				                    <img src="img/blog-content-img.png" class="img-fluid"/>
				                    <div class="image-content">
				                      <p>Image caption or credit</p>
				                    </div>
				                    <p>The toppings you may chose for that TV dinner pizza slice when you forgot to shop for foods, the paint you may slap on your face to impress the new boss is your business. But what about your daily bread? Not so fast, I'd say, there are some redeeming factors in favor of greeking text, as its use is merely the symptom of a worse problem to take into consideration.</p>
				                    <p>Design comps, layouts, wireframes—will your clients accept that you go about things the facile way? Authorities in our business will tell in no uncertain terms that Lorem Ipsum is that huge, huge no no to forswear forever. </p>
				                    <div class="p-card">
				                      <h6>Design comps, layouts, wireframes—will your clients accept that you go about things the facile way? Authorities in our business will tell in no uncertain terms that Lorem Ipsum is that huge, huge no no to forswear forever.</h6>
				                    </div>
				                    <p>Design comps, layouts, wireframes—will your clients accept that you go about things the facile way? Authorities in our business will tell in no uncertain terms that Lorem Ipsum is that huge, huge no no to forswear forever.</p>
				                    <p>The toppings you may chose for that TV dinner pizza slice when you forgot to shop for foods, the paint you may slap on your face to impress the new boss is your business. But what about your daily bread? Not so fast, I'd say, there are some redeeming factors in favor of greeking text, as its use is merely the symptom of a worse problem to take into consideration.</p>
				                    <p>Not so fast, I'd say, there are some redeeming factors in favor of greeking text, as its use is merely the symptom of a worse problem to take into consideration.</p>
				                    <p>Design comps, layouts, wireframes—will your clients accept that you go about things the facile way? Authorities in our business will tell in no uncertain terms that Lorem Ipsum is that huge, huge no no to forswear forever. </p>
				                </div>
				            </div>
				        </div>
				        </div>
				      </section>
				<Footer/>
				
			</>
		);
}
export default BlogDetail;

