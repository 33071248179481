import React from 'react';
const PageCounter=()=>{
	return(
			<>
				 <section>
			        <div className="container-fluid">
			          <div className="counter-main">
			            <div className="counter">
			              <h3>20<span>k</span></h3>
			              <p>Clients Base</p>
			            </div>
			            <div className="counter">
			              <h3>200<span>+</span></h3>
			              <p>Team Members</p>
			            </div>
			            <div className="counter">
			              <h3>10<span>X</span></h3>
			              <p>Boost in Revenue</p>
			            </div>
			            <div className="counter">
			              <h3>15<span>+</span></h3>
			              <p>Countries</p>
			            </div>
			          </div>
			        </div>
			      </section>
			</>
		);
}

export default PageCounter;