import React from 'react';
import XMLViewer from 'react-xml-viewer'
const Sitemap=()=>{
	const xml = '<hello>World</hello>'
	return (
			<>
				<XMLViewer xml={xml} />
			</>
		)
}
export default Sitemap;