import React,{useState,useRef,useEffect} from 'react';
import axios from 'axios';
import {api_url} from '../basic';
import {Link,useNavigate} from 'react-router-dom';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {login,logout} from '../../app/userSlice';
const Modal=()=>{	
const navigate =useNavigate();
const dispatch = useDispatch();
const [mobile,setMobile]=useState('');
const [cntry,setCntry]=useState('+91');
const [mobileerror,setMobileerror]=useState(true);
const [otp,setOtp]=useState('');
const [otpdata,setOtpdata]=useState({
	first:'',
	second:'',
	third:'',
	fourth:''
})
const [otpfirst,setOtpfirst]=useState(true);
const [otpsecond,setOtpsecond]=useState(true);
const [otpthird,setOtpthird]=useState(true);
const [otpfourth,setOtpfourth]=useState(true);
const {first,second,third,fourth}=otpdata;

const handlemobile = (e)=>{
	const re = /^[0-9\b]+$/;    
    if (e.target.value === '' || re.test(e.target.value)) {
       setMobile(e.target.value)
    }	
}
const handlecntry=(e)=>{
	setCntry(e.target.value);
}
const validatemobile=()=>{
	if(mobile===''){
		setMobileerror(false);
	}else{
		setMobileerror(true);
	}
	if(mobile===''){
		return false;
	}else{
		return true;
	}	
}
const sendotp=async()=>{
	if(validatemobile()==true){		
			try{
				let form =new FormData();
				form.append('dir_mobile',mobile); 
				const otp_sent = await axios.post(api_url+'send-otp',form);
				const otp_Data = JSON.parse(JSON.stringify(otp_sent.data));
				if(otp_Data.status==1){
					setOtp(otp_Data.data.otp);
					document.getElementById('otpmodal').click();
					toast.success('Otp sent to your mobile no')	
				}else{
					toast.error('mobile no does not exist');
				}
			}catch(e){
				return false;
			}
	}else{
		return false;
	}
}

const handleOTP=(e)=>{
	const re = /^[0-9\b]+$/;	
	if (re.test(e.target.value)) {  
		setOtpdata({...otpdata,[e.target.name]:e.target.value});
	}
}

const validateOTP=(e)=>{
	if(first===''){
		setOtpfirst(false);
	}else{
		setOtpfirst(true);
	}
	if(second===''){
		setOtpsecond(false);
	}else{
		setOtpsecond(true);
	}
	if(third===''){
		setOtpthird(false);
	}else{
		setOtpthird(true);
	}
	if(fourth===''){
		setOtpfourth(false);
	}else{
		setOtpfourth(true);
	}

	if(first==='' || second==='' || third==='' || fourth===''){
		return false;
	}else{
		return true;
	}
}
const VerifyOTP=async()=>{
	if(validateOTP()==true){
		let fullotp=first+second+third+fourth;
		try{
			let form = new FormData();
			form.append('mobile',mobile);
			form.append('otp',fullotp);
			const login_data = await axios.post(api_url+'login-otp',form);
			const login_js_data = JSON.parse(JSON.stringify(login_data.data));
			if(login_js_data.status==1){
				dispatch(login({
					cid:login_js_data.data.company_details.id,
					cname:login_js_data.data.company_details.company_name,
					dirname:login_js_data.data.company_details.dir_name,
					token:login_js_data.data.token,
					mobile:login_js_data.data.company_details.mobile,
					email:login_js_data.data.company_details.email
				}));
				localStorage.setItem('web_dname',login_js_data.data.company_details.dir_name);
				localStorage.setItem('web_token',login_js_data.data.token);
				localStorage.setItem('cid',login_js_data.data.company_details.id);
				localStorage.setItem('mobile',login_js_data.data.company_details.mobile);
				localStorage.setItem('email',login_js_data.data.company_details.email);				
				document.getElementById('otpCloseBtn').click();
				navigate('/profile');
				toast.success('Loggedin successfull');
			}else{
				toast.error('something went wrong');
			}
		}catch(e){
			return false;
		}		
	}else{
		return false;
	}
}

const handleLogin=()=>{
	document.getElementById('loginCloseBtn').click();
}

	return(
			<>
			<ToastContainer />
				 <div className="modal fade" data-bs-backdrop="static" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
				  <div className="modal-dialog modal-lg modal-dialog-centered">
				    <div className="modal-content">
				        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="loginCloseBtn"></button>
				      <div className="modal-body step-form-right login-form">
				        <img src="img/login-img1.jpg" width="290px" className="img-fluid"/>
				        <h3>Login</h3>				       
				        <div className="input-group">
				        <select className="form-select w-25" aria-label="Default select example" onChange={(e)=>handlecntry(e)}>
				          <option value="+91">+91</option>
				          <option value="+89">+89</option>
				          <option value="+30">+30</option>
				          <option value="+20">+20</option>
				        </select>
				          <input type="text" className={"form-control w-75"+(mobileerror!=true?' error-warning':'')} aria-label="Text input with dropdown button" placeholder="Enter Your Moblie Number" inputmode="numeric" maxlength="10" onChange={(e)=>handlemobile(e)} value={mobile}/>
				        </div>
				        <button className="btn send-otp-btn" onClick={sendotp}>Send OTP</button>
				        	<p>if don't have account <Link to="/signup" onClick={handleLogin}>click here</Link></p>
				      </div>
				    </div>
				    <button className="btn send-otp-btn" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" id="otpmodal" style={{"display":"none"}}>click</button>
				  </div>
				</div>
				<div className="modal fade" data-bs-backdrop="static" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1" >
				  <div className="modal-dialog modal-dialog-centered">
				 <div className="modal-content">
				      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="otpCloseBtn"></button>
				<div className="step-form-right login-form">
				          <div className="tab">
				            <div className="tab-heading">
				              <p><img src="img/checkbox-icon.png" className="img-fluid me-2" />OTP sent to your Mobile Number</p>
				              
				            </div>
				         <div id="otp" className="inputfield"> 
				          <input className={"input custom_otp"+(otpfirst!=true?' error-warning':'')} type="text" id="first" name="first" maxlength="1" tabindex="1" autocomplete="off" onChange={(e)=>handleOTP(e)}/> 
				          <input className={"input custom_otp"+(otpsecond!=true?' error-warning':'')} type="text" id="second" name="second" maxlength="1" tabindex="2" autocomplete="off" onChange={(e)=>handleOTP(e)}/> 
				          <input className={"input custom_otp"+(otpthird!=true?' error-warning':'')} type="text" id="third" name="third" maxlength="1" tabindex="3" autocomplete="off" onChange={(e)=>handleOTP(e)}/> 
				          <input className={"input custom_otp"+(otpfourth!=true?' error-warning':'')} type="text" id="fourth" name="fourth" maxlength="1" tabindex="4" autocomplete="off" onChange={(e)=>handleOTP(e)}/>
				        </div>
				          <div className="hide-on-desktop otp-text">
				            <a href="#" className="send-otp-text">Resend OTP</a>
				          </div>
				          </div>
				            <div className="step-form-footer">
				            <button type="submit" id="next_button" className="btn next" onClick={VerifyOTP}>Verify OTP</button>
				        </div>
				        </div>
				    </div>
				  </div>
				</div>
			</>
		);

}

export default Modal;