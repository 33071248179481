import React,{useState,useEffect} from 'react';
import {Link,useLocation} from 'react-router-dom';
import Header from '../layouts/header';
import Footer from '../layouts/Footer';
import Modal from '../layouts/modal';
import PageCounter from './counter';
import PageBlog from './pageblog';
import PageSubscribe from './pagesubscribe';
import axios from 'axios';
import {api_url,web_url,img_url} from '../basic';
import { useSelector, useDispatch } from 'react-redux';
import {selectUser,logout,selectPage_id,page_id} from '../../app/userSlice';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const EditProfile=({setIsEdit,isEdit,plan_id,companydata,cmpid})=>{
	const user = useSelector(selectUser);
	const dispatch = useDispatch();

	const backOrder=()=>{
		setIsEdit(false);
	}
  const [dirError,setDirError]=useState(true);
  const [emailError,setEmailError]=useState(true);
  const [mobileError,setMobileError]=useState(true);

  const [companyProfile,setCompanyProfile]=useState({
    dirname:companydata && companydata.dir_name,
    mobile:companydata && companydata.mobile,
    email:companydata && companydata.email,
    id:cmpid
  });
  const {dirname,mobile,email}=companyProfile;
  const handlechange=(e)=>{
    setCompanyProfile({...companyProfile,[e.target.name]:e.target.value});
  }

  const testvalidate=()=>{
    if(dirname===''|| dirname===null){
      setDirError(false);
    }else{
      setDirError(true);
    }
    if(mobile===''|| mobile===null){
      setMobileError(false);
    }else{
      setMobileError(true);
    }
    if(email===''|| email===null){
      setEmailError(false);
    }else{
      setEmailError(true);
    }
    if(dirname==='' || email===''|| mobile===''){
      return false;
    }else{
      return true;
    }
  }

  const submitPro=async()=>{
      if(testvalidate()===true){
          try{                   
              const update_company = await axios.post(api_url+'update-company-profile',companyProfile,{ headers: {"Authorization" : `Bearer ${user.token}`}});
              const update_data = JSON.parse(JSON.stringify(update_company.data));
              if(update_data.status==1){
                toast.success('Profile updated successfull');
              }else{
                toast.error('something went wrong');
              }
          }catch(e){
           toast.error('something went wrong');
          }
      }else{
       return false;
      }
  }



	return (
			<>			
		    <ToastContainer />
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="card profile">
              <div className="profile-heading pt-0">
            <i className="fa fa-angle-left back-icon me-2 float-start" onClick={backOrder}></i>
            <h4 className="mb-0">Edit Profile</h4>
          </div>
                <div className="text-center">
                <img src="img/dashboard-profile-img.png" className="img-fluid rounded-circle"/>
              </div>
                <div className="profile-img">
                    <button type="button" className="btn btn-sm choose me-3">Choose Photo</button>
                    <button type="button" className="btn btn-sm delete">Delete</button>
                </div>
                <div className="row">
                  <div className="col mb-2">
                  <label className="form-label">Full Name</label>
                  <input type="text" className={"form-control form-control-sm"+(dirError!=true?' error-warning':'')} placeholder="Full Name" value={dirname} name="dirname" onChange={(e)=>handlechange(e)}/>
                </div>
                <div className="col mb-2">
                  <label className="form-label">Email address</label>
                  <input type="email" className={"form-control form-control-sm"+(emailError!=true?' error-warning':'')} placeholder="Email Address" value={email} name="email" onChange={(e)=>handlechange(e)}/>
                </div>
              </div>
              <div className="row">
                <div className="col mb-2">
                  <label className="form-label">Phone Number</label>
                  <input type="tel" className={"form-control form-control-sm"+(mobileError!=true?' error-warning':'')} placeholder="Phone Number" value={mobile} name="mobile" onChange={(e)=>handlechange(e)}/>
                </div>
               
                <div className="col"></div>
              </div>
              <div className="text-center">
                <button type="button" className="btn btn-primary btn-sm w-25" onClick={submitPro}>Update</button>
              </div>
              </div>
            </div>
          </div>
		    
	
				
			</>
		);
}
export default EditProfile;

