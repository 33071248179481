import { createSlice } from '@reduxjs/toolkit'

export const pathSlice = createSlice({
    name:"path",
    initialState:{
      path:null,     
    },
    reducers:{
      create_path:(state,action)=>{
        state.path=action.payload;
      },
    
    },
});

export const { create_path } = pathSlice.actions;
export const selectPath=(state)=>state.path.path;
export default pathSlice.reducer;