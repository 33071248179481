import React from 'react';
import {Link} from 'react-router-dom';
const PageBlog=()=>{
	return(
			<>
				
				      <section id="blog-section">
				        <div className="container">
				        <div className="blog-heading">
				          <h3>Blogs</h3>
				        </div>
				          <div className="row">
				            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
				              <div className="blog-content">
				                <span className="weekly">Weekly updates</span><span>March 22, 2023 </span>
				                <h3><Link to="/blog-detail">10 Hilarious Cartoons That Depict Real-Life Problems of Programmers</Link></h3>
				                <p>Redefined the user acquisition and redesigned the onboarding experience, all within 3 working weeks.</p>
				              </div>
				            </div>
				            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 text-end">
				              <div className="blog-image">
				                <Link to="/blog-detail"><img src="img/blog-1.png" width="300px" className="img-fluid" /></Link>
				              </div>
				            </div>
				          </div>
				          <div className="line"></div>
				          <div className="row">
				            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
				              <div className="blog-content">
				                <span className="weekly">Weekly updates</span><span>March 22, 2023 </span>
				                <h3><Link to="/blog-detail">10 Hilarious Cartoons That Depict Real-Life Problems of Programmers</Link></h3>
				                <p>Redefined the user acquisition and redesigned the onboarding experience, all within 3 working weeks.</p>
				              </div>
				            </div>
				            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 text-end">
				              <div className="blog-image">
				                <Link to="/blog-detail"><img src="img/blog-2.png" width="300px" className="img-fluid"/></Link>
				              </div>
				            </div>
				          </div>
				         <div className="line"></div>
				          <div className="row">
				            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
				              <div className="blog-content">
				                <span className="weekly">Weekly updates</span><span>March 22, 2023 </span>
				                <h3><Link to="/blog-detail">10 Hilarious Cartoons That Depict Real-Life Problems of Programmers</Link></h3>
				                <p>Redefined the user acquisition and redesigned the onboarding experience, all within 3 working weeks.</p>
				              </div>
				            </div>
				            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 text-end">
				              <div className="blog-image">
				                <Link to="/blog-detail"><img src="img/blog-3.png" width="300px" className="img-fluid"/></Link>
				              </div>
				            </div>
				          </div>
				          <div className="more-articles">
				            <Link to="#" className="btn article">More articles</Link>
				          </div>
				        </div>
				      </section>
			</>
		);
}
export default PageBlog;