import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Modal from '../layouts/modal';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {api_url,web_url,img_url} from '../basic';
import {selectPath,create_path} from '../../app/pathSlice';
import {selectUser} from '../../app/userSlice';
const RequestDemo=()=>{
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const [fullnameError,setFullnameError]=useState(true);
	const [emailError,setEmailError]=useState(true);
	const [mobileError,setMobileError]=useState(true);
	const [industryError,setIndustryError]=useState(true);
	const [messageError,setMessageError]=useState(true);
	const [otp,setOtp]=useState('');
	const [otpfirst,setOtpfirst]=useState(true);
	const [otpsecond,setOtpsecond]=useState(true);
	const [otpthird,setOtpthird]=useState(true);
	const [otpfourth,setOtpfourth]=useState(true);
	const [socialLink,setSocialLink]=useState([]);
	const [industrylist,setIndustrylist]=useState([]);
	const [request,setRequest]=useState({
		fullname:user!=null?user.dirname:'',
		email:user!=null?user.email:'',
		mobile:user!=null?user.mobile:'',
		industry:'0',
		message:''
	})
	const [otpdata,setOtpdata]=useState({
		first:'',
		second:'',
		third:'',
		fourth:''
	})
	const {fullname,email,mobile,industry,message}=request;
	const {first,second,third,fourth}=otpdata;
	useEffect(()=>{
		loadSocialLink();
		loadIndustry();
			dispatch(create_path({
		path:window.location.pathname
	}))
	},[])
	const handlechange=(e)=>{
		setRequest({...request,[e.target.name]:e.target.value});
	}
	const handlemobile = (e)=>{
		const re = /^[0-9\b]+$/;    
	    if (e.target.value === '' || re.test(e.target.value)) {
	       setRequest({...request,mobile:e.target.value})
	    }	
	}
	const ValidateRequest=()=>{
		if(fullname===''){
			setFullnameError(false);
		}else{
			setFullnameError(true);
		}
		if(email===''){
			setEmailError(false);
		}else{
			setEmailError(true);
		}
		if(mobile===''){
			setMobileError(false);
		}else{
			setMobileError(true);
		}
		if(industry==='0'){
			setIndustryError(false);
		}else{
			setIndustryError(true);
		}
		if(message===''){
			setMessageError(false);
		}else{
			setMessageError(true);
		}

		if(fullname===''||email===''||mobile===''||industry===''||message===''){
			return false;
		}else{
			return true;
		}
	}

const handledemo=async()=>{
	if(ValidateRequest()==true){		
			try{
				var pathname=window.location.pathname;
				var form =  {"mobile":mobile,"name":fullname,"email":email,"industry":industry,"message":message,"pathname":pathname};			
				const otp_sent = await axios.post(web_url+'send-otp-request',form);
				const otp_Data = JSON.parse(JSON.stringify(otp_sent.data));
				if(otp_Data.status==200){
					setOtp(otp_Data.result.otp);					
					document.getElementById('otpmodal').click();
					toast.success('Otp sent to your mobile no')	
				}else{
					toast.error('mobile no does not exist');
				}
			}catch(e){
				return false;
			}
	}else{
		return false;
	}
}

const handleOTP=(e)=>{
	const re = /^[0-9\b]+$/;	
	if (re.test(e.target.value)) {  
		setOtpdata({...otpdata,[e.target.name]:e.target.value});
	}
}

const validateOTP=(e)=>{
	if(first===''){
		setOtpfirst(false);
	}else{
		setOtpfirst(true);
	}
	if(second===''){
		setOtpsecond(false);
	}else{
		setOtpsecond(true);
	}
	if(third===''){
		setOtpthird(false);
	}else{
		setOtpthird(true);
	}
	if(fourth===''){
		setOtpfourth(false);
	}else{
		setOtpfourth(true);
	}

	if(first==='' || second==='' || third==='' || fourth===''){
		return false;
	}else{
		return true;
	}
}

const VerifyOTP=async()=>{
	if(validateOTP()==true){
		let fullotp=first+second+third+fourth;
		try{
			let form = {"mobile":mobile,"otp":fullotp,"name":fullname,"email":email,"industry":industry,"message":message};
			const login_data = await axios.post(web_url+'verify-otp-request',form);
			const login_js_data = JSON.parse(JSON.stringify(login_data.data));
			if(login_js_data.status==200){
				toast.success('Request Submitted successfull');
				document.getElementById('otpCloseBtn').click();
			}else{
				toast.error('something went wrong');
			}
		}catch(e){
			return false;
		}		
	}else{
		return false;
	}
}

const loadSocialLink=async()=>{
		try{
				const social_link=await axios.get(web_url+'social-icon-list');
				const data = JSON.parse(JSON.stringify(social_link.data));
				if(data.status==200){
					setSocialLink(data.result);
				}
		}catch(e){
			return false;
		}
	}

	const loadIndustry=async()=>{
		try{
				const social_link=await axios.get(web_url+'web-list-industry');
				const data = JSON.parse(JSON.stringify(social_link.data));
				if(data.status==200){
					setIndustrylist(data.result);
				}
		}catch(e){
			return false;
		}
	}

	const loadmetatags=async(p_id)=>{
		try{
			const page_seo = await axios.get(web_url+'website-seo?page_id='+`${p_id}`);
			const data = JSON.parse(JSON.stringify(page_seo.data));
			if(data.status==200){
				let fullpath = window.location.href;
				document.querySelector('meta[name="description"]').setAttribute('content', data.result.meta_description);
				document.querySelector('meta[name="keywords"]').setAttribute('content', data.result.meta_keywords);
				document.querySelector('title').innerHTML=data.result.meta_title;
				document.querySelector('meta[property="og:title"]').setAttribute('content', data.result.meta_title);
				document.querySelector('meta[property="og:url"]').setAttribute('content', fullpath);
				document.querySelector('link[rel="canonical"]').setAttribute('href', fullpath);
				document.querySelector('meta[property="og:description"]').setAttribute('content', data.result.meta_description);
				document.querySelector('meta[property="og:site_name"]').setAttribute('content', 'closecall');
				document.querySelector('meta[property="og:image"]').setAttribute('content', localStorage.getItem('logo_url'));
			}else{
				const desctiption="Closecall Real-Time Call Tracking Platform";
				document.querySelector('meta[name="description"]').setAttribute('content', desctiption);
				document.querySelector('meta[name="keywords"]').setAttribute('content', desctiption);
				document.querySelector('title').innerHTML="closecall";
			}

		}catch(e){
			const desctiption="Closecall Real-Time Call Tracking Platform";
			document.querySelector('meta[name="description"]').setAttribute('content', desctiption);
			document.querySelector('meta[name="keywords"]').setAttribute('content', desctiption);
			document.querySelector('title').innerHTML="closecall";
		}
		
	}


	return(
			<>
			<ToastContainer />
					<section id="request-demo">
	<div className="container">
		<div className="row">
			<div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
				<div className="rquest-form">
					<Link to="/"><img src="img/right-arrow-icon.png" width="21px" className="img-fluid" alt="back home"/></Link>
					<h6>REQUEST A DEMO</h6>
					<h2>Let's schedule a demo</h2>				
						<div className="mb-3">
						<input type="text" className={"form-control"+(fullnameError!=true?' error-warning':'')} id="exampleFormControlInput1" placeholder="Name" name="fullname" onChange={(e)=>handlechange(e)} value={fullname}/>
						</div>
						<div className="mb-3">
						<input type="email" className={"form-control"+(emailError!=true?' error-warning':'')} id="exampleFormControlInput1" placeholder="Email" name="email" onChange={(e)=>handlechange(e)} value={email}/>
						</div>
						<div className="mb-3">
						<input type="tel" className={"form-control"+(mobileError!=true?' error-warning':'')} id="exampleFormControlInput1" placeholder="Phone Number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" maxlength="10" name="mobile" onChange={(e)=>handlemobile(e)} value={mobile}/>
						</div>
						<div className="mb-3">
							<select className={"form-select"+(industryError!=true?' error-warning':'')} aria-label="Default select example" name="industry" onChange={(e)=>handlechange(e)}>
							  <option value="0">Select your Industry</option>
							  {
							  	industrylist.map((item)=>(
							  		<>
							  		 <option value={item.id}>{item.name}</option>
							  		</>
							  		))
							  }							 
							</select>
						</div>
						<div className="mb-4">
						<textarea className={"form-control"+(messageError!=true?' error-warning':'')} id="exampleFormControlTextarea1" placeholder="Your Message" rows="5" name="message" onChange={(e)=>handlechange(e)} style={{'height':'150px'}}></textarea>
						</div>
						<div>
						<button className="btn request-demo" onClick={handledemo}>Request a Demo</button>						
						</div>					
				</div>
			</div>
			<div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
				<div className="rquest-demo-image">
					<div className="icons">
					 {
		                	socialLink.map((item,index)=>(
		                		<>
		                			<Link to={item.link} target="_blank"><img src={img_url+item.icon} alt={item.title} className="img-fluid me-1" width="26px"/></Link>
		                		</>
		                	))
		                }
						
					</div>
					<img src="img/request-demo-img.png" className="img-fluid" width="583px" alt="request demo"/>
				</div>
			</div>
		</div>
	</div>
</section>
    <button className="btn send-otp-btn" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" id="otpmodal" style={{"display":"none"}}>click</button>
<div className="modal fade" data-bs-backdrop="static" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1" >
				  <div className="modal-dialog modal-dialog-centered">
				 <div className="modal-content">
				      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="otpCloseBtn"></button>
				<div className="step-form-right login-form">
				          <div className="tab">
				            <div className="tab-heading">
				              <p><img src="img/checkbox-icon.png" className="img-fluid me-2" alt="otp"/>OTP sent to your Mobile Number</p>
				             
				            </div>
				         <div id="otp" className="inputfield"> 
				          <input className={"input custom_otp"+(otpfirst!=true?' error-warning':'')} type="text" id="first" name="first" maxlength="1" tabindex="1" autocomplete="off" onChange={(e)=>handleOTP(e)}/> 
				          <input className={"input custom_otp"+(otpsecond!=true?' error-warning':'')} type="text" id="second" name="second" maxlength="1" tabindex="2" autocomplete="off" onChange={(e)=>handleOTP(e)}/> 
				          <input className={"input custom_otp"+(otpthird!=true?' error-warning':'')} type="text" id="third" name="third" maxlength="1" tabindex="3" autocomplete="off" onChange={(e)=>handleOTP(e)}/> 
				          <input className={"input custom_otp"+(otpfourth!=true?' error-warning':'')} type="text" id="fourth" name="fourth" maxlength="1" tabindex="4" autocomplete="off" onChange={(e)=>handleOTP(e)}/>
				        </div>
				          <div className="hide-on-desktop otp-text">
				            <a href="#" className="send-otp-text">Resend OTP</a>
				          </div>
				          </div>
				            <div className="step-form-footer">
				            <button type="submit" id="next_button" className="btn next" onClick={VerifyOTP}>Verify OTP</button>
				        </div>
				        </div>
				    </div>
				  </div>
				</div>
			</>
		);
}
export default RequestDemo;