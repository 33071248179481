import React,{useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Header from '../layouts/header';
import Footer from '../layouts/Footer';
import {api_url} from '../basic';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {login,logout} from '../../app/userSlice';
const Signup=()=>{  
	const navigate=useNavigate();
	const dispatch = useDispatch();
	const [signupData,setSignupData]=useState({
		company_name:'',
		username:'',
		email:'',
		country_code:'+91',
		mobile:''
	});
	const [companyNameError,setCompanyNameError]=useState(true);
	const [userNameError,setUserNameError]=useState(true);
	const [emailError,setEmailError]=useState(true);
	const [countryCodeError,setCountryCodeError]=useState(true);
	const [mobileError,setMobileError]=useState(true);
	const [otpsend,setOtpsend]=useState(false);
	const [otpshow,sentOtpshow]=useState('');
	const [otp,setOTP]=useState('');
	const [otpError,setOtpError]=useState(true);
	const [otpVrfy,setOtpVrfy]=useState(false);	
	const {company_name,username,email,country_code,mobile}=signupData;
	const handlechange=(e)=>{
		setSignupData({...signupData,[e.target.name]:e.target.value});
	}
	const handlemobile = (e)=>{
		const re = /^[0-9\b]+$/;    
	    if (e.target.value === '' || re.test(e.target.value)) {
	       setSignupData({...signupData,mobile:e.target.value});
	    }
	    if(mobile.length<10){
	    	setOtpsend(false);
	    	setOtpVrfy(false);
	    	setOTP('');
	    }	
	}

	const handleOtp=(e)=>{
		const re = /^[0-9\b]+$/;    
	    if (e.target.value === '' || re.test(e.target.value)) {
	       setOTP(e.target.value);
	    }
	}

	const validateOTP=()=>{
		if(otp==='' || otp.length>4){
			setOtpError(false);
		}else{
			setOtpError(true);
		}

		if(otp==='' || otp.length>4 ){
			return false;
		}else{
			return true
		}
	}

	const validateSignup=()=>{
		if(company_name===''){
			setCompanyNameError(false);
		}else{
			setCompanyNameError(true);
		}
		if(username===''){
			setUserNameError(false);
		}else{
			setUserNameError(true);
		}
		if(email===''){
			setEmailError(false);
		}else{
			setEmailError(true);
		}
		if(country_code===''){
			setCountryCodeError(false);
		}else{
			setCountryCodeError(true);
		}
		if(mobile==='' || mobile.length<10){
			setMobileError(false);
		}else{
			setMobileError(true);
		}

		if(company_name==='' || username==='' || email==='' || country_code==='' || mobile==='' || mobile.length<10){
			return false;
		}else{
			return true;
		}
	}

	const SignupBtn=async(e)=>{
		e.preventDefault();
		if(validateSignup()==true){			
			if(otpVrfy==false){
				toast.error('mobile no does not verified');
				return false;
			}
			try{
				let form = new FormData();
				form.append('company_name',company_name);
				form.append('dir_name',username);
				form.append('dir_email',email);
				form.append('dir_mobile',mobile);
				const create_account = await axios.post(api_url+'company-signup',form);
				const js_data = JSON.parse(JSON.stringify(create_account.data));
				if(js_data.status==1){
					dispatch(login({
						cid:js_data.data.create_company.id,
						cname:js_data.data.create_company.company_name,
						dirname:js_data.data.create_company.dir_name,
						token:js_data.data.token
					}));
					localStorage.setItem('web_dname',js_data.data.create_company.dir_name);
					localStorage.setItem('web_token',js_data.data.token);
					localStorage.setItem('cid',js_data.data.create_company.id);				
					navigate('/profile');
					toast.success('Account created successfull');					
				}else if(js_data.status==2){
					toast.error('Mobile no does not verified');
				}else{
					toast.error('something went wrong');
				}				
			}catch(e){
				return false;
			}

		}else{			
			return false;
		}
	}



	/*-----------verify mobile otp--------*/
	const sentotp=async()=>{
		try{
			setOtpsend(true);
			let form = new FormData();
			form.append('mobile',mobile);
			const otpsend = await axios.post(api_url+'verify-mobile',form);
			const otp_data = JSON.parse(JSON.stringify(otpsend.data));
			if(otp_data.status==1){
				toast.success('Otp sent to your mobile no');
				sentOtpshow(otp_data.data.otp);
			}
		}catch(e){
			return false;
		}
	}

	const verifyotp=async()=>{
		if(validateOTP()==true){
			try{
				let form = new FormData();
				form.append('mobile',mobile);
				form.append('otp',otp);
				const verify_otp=await axios.post(api_url+'verify-mobile-otp',form);
				const verify_otp_data = JSON.parse(JSON.stringify(verify_otp.data));
				if(verify_otp_data.status==1){
					toast.success('Mobile no verified successfull');
					setOtpVrfy(true);
				}else{
					toast.error('mobile or otp is wrong');
				}
			}catch(e){
				return false;
			}
		}else{
			return false;
		}
	}
	
	
	return(
		<>
		 	
		 	<ToastContainer/>
			<section id="signup">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
							<div className="signup-image">
								<img src="img/signup-image.png" className="img-fluid" width="583px" alt="signup"/>
							</div>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
							<div className="signup-form">
								<h2>Create an Account</h2>
								<p>Lorem ipsum dolor sit amet consectet adipiscing elit eget </p>
								<form>
									<div className="mb-3">
									<input type="text" className={"form-control"+(companyNameError!=true?' error-warning':'')} id="exampleFormControlInput1" placeholder="Company Name" name="company_name" onChange={(e)=>handlechange(e)}/>
									</div>
									<div className="mb-3">
									<input type="text" className={"form-control"+(userNameError!=true?' error-warning':'')} id="exampleFormControlInput1" placeholder="Username" name="username" onChange={(e)=>handlechange(e)}/>
									</div>
									<div className="mb-3">
									<input type="email" className={"form-control"+(emailError!=true?' error-warning':'')} id="exampleFormControlInput1" placeholder="Email" name="email" onChange={(e)=>handlechange(e)}/>
									</div>
									<div className="d-flex mb-3 align-items-center position-relative">
			        <select className={"form-select w20"+(countryCodeError!=true?' error-warning':'')} aria-label="Default select example" name="country_code" onChange={(e)=>handlechange(e)}>
			          <option selected>+91</option>
			          <option value="1">+89</option>
			          <option value="2">+30</option>
			          <option value="3">+20</option>
			        </select>
			          <input type="text" className={"form-control signup-mb"+(mobileError!=true?' error-warning ':'')+(otpVrfy==false?mobile.length==10?' w50':' w-75':' w-75')} aria-label="Text input with dropdown button" placeholder="Enter Your Moblie Number" name="mobile" onChange={(e)=>handlemobile(e)} value={mobile} maxlength="10"/>{otpVrfy==true?<span className="check-icon fa fa-check-circle"></span>:null}
			          	{otpVrfy==false?mobile.length==10?<><button type="button" className="verify" onClick={otpsend==true?verifyotp:sentotp}>{otpsend==true?'Verify':'Send OTP'}</button></>:null:null}
			        </div>	

			        {otpVrfy==false?otpsend==true?<>	
									<div className="mb-3">									
									<input type="text" className={"form-control"+(otpError!=true?' error-warning':'')} id="exampleFormControlInput1" placeholder="Verify OTP" maxlength="4" onChange={(e)=>handleOtp(e)} value={otp}/>
									</div>
									</>:null:null
								}
									<div>
									<button className="btn request-demo" onClick={(e)=>SignupBtn(e)}>Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		</>
		);
}

export default Signup;