import React, { useCallback,useState,useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {api_url,web_url,img_url} from '../basic';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {selectUser,logout,selectPage_id,page_id} from '../../app/userSlice';
import useRazorpay from "react-razorpay";
import axios from 'axios';
const WalletDetails = ({ setIsWallet, isWallet }) => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [amounterror, setAmounterror] = useState(true);
  const [amount, setAmount] = useState(100);
  const [walletData,setWalletData]=useState(null);
  useEffect(()=>{
    loadwallet();
  },[]);
  const backpage = () => {
    setIsWallet(false);
  };

  const addwalletamount = () => {
    document.getElementById("amountwallet").click();
  };
  const handleChange = (e) => {
    setAmount(e.target.value);
  };
  /*------------wallet details--------*/
  const loadwallet=async()=>{
    try{

      const upcmp = await axios.get(api_url+'wallet-history?cmp_id='+`${user.cid}`,{ headers: {"Authorization" : `Bearer ${user.token}`}});
      const upcmp_data = JSON.parse(JSON.stringify(upcmp.data));
      if(upcmp_data.status==1){
        setWalletData(upcmp_data.data);
      }

    }catch(e){
      return false;
    }
  }

  /*-----------add payment to wallet-------*/

   const Razorpay = useRazorpay();

  const HandleOrder = async ({ total_amt }) => {
    try {
      const data = {
        plan_id: "wallet",
        company_id: user.cid,
        wallet_amt:amount
      };
      const response = await axios.post(
        api_url+'create-order',
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status == 200) {
        let orderId = response.data.data.request.order_id;
        let buyPlanId = response.data.data.request.buy_plan_id;
        let Amount = response.data.data.request.amount;

        const options = {
          key: response.data.data.request.key,
          // amount: response.data.data.request.amount,
          // currency: "INR",
          order_id: response.data.data.request.order_id,
          name: "CloseCall",
          description: "Enterslice ",
          image: "img/close-logo.png",
          handler: async function (res, response) {
            // Check if the payment is successful
            if (res.razorpay_payment_id) {
              const transactionId = res.razorpay_payment_id;
              //  alert("Payment Successful! Transaction ID: " + transactionId);

              const paymentData = {
                company_id: user.cid,
                plan_id: "wallet",
                buy_plan_id: "wallet",
                order_id: orderId,
                transaction_id: transactionId,
                amount: Amount,
                payment_mode: "3",
                status: "2",
              };

              const response = await axios.post(
                api_url+"payment-status",
                paymentData,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              const resCity = JSON.parse(JSON.stringify(response.data));

              if (resCity) {
                document.getElementById("loginCloseBtn").click();
                navigate("/payment-success");
              }
              console.log(resCity, "response");
            } else {
              alert("Payment Failed!");
            }
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#0742A6",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="card">
        <div className="card-body pt-0">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="mb-0">
              <i
                className="fa fa-angle-left back-icon me-2"
                onClick={backpage}
              ></i>
              Wallet Details
            </h2>
            <button
              type="button"
              className="btn btn-sm add-on me-2 mb-2"
              onClick={addwalletamount}
            >
              <i className="fa fa-plus me-2"></i>Add Wallet Amount
            </button>
          </div>
          <div className="row mb-3">
            <div className="col-md-3 mb-3">
              <div className="d-flex wallet-card">
                <div>
                  <h4>Total Amount</h4>
                  <p className="mb-0"> &#8377; {walletData && walletData.total_credit}</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="d-flex wallet-card">
                <div>
                  <h4>Used Amount</h4>
                  <p className="mb-0"> &#8377; {walletData && walletData.used_amt}</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="d-flex wallet-card">
                <div>
                  <h4>Available Amount</h4>
                  <p className="mb-0"> &#8377; {walletData && walletData.available_amt}</p>
                </div>
              </div>
            </div>
            {/*<div className="col-md-3 mb-3">
                          <div className="d-flex wallet-card">
                            <div>
                              <h4>Total Amount</h4>
                              <p className="mb-0"> &#8377; 300</p>
                            </div>
                          </div>
                        </div>*/}
          </div>
          <h2>
            <span className="fa fa-file-text me-2"></span> Wallet History
          </h2>
          <div className="table-responsive bg-white mt-4">
            <table className="table">
              <thead>
                <tr>
                 
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Remark</th>
                  <th scope="col">Credit</th>
                  <th scope="col">Debit</th>
                  <th scope="col">Balance</th>             
                  
                </tr>
              </thead>
              <tbody>
              {
                walletData && walletData.getData.length>0?
                <>
                {
                  walletData.getData.map((item)=>(
                    <>
                      <tr>                    
                      <td>{item.transaction_id}</td>
                      <td>{item.remark}</td>
                      <td>{item.credit}</td>
                      <td>{item.debit}</td>
                      <td>{item.credit-item.debit}</td>                      
                      
                     
                    </tr>
                    </>
                    ))
                }
                 
                </>
                :
                null
              }        
                

              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        data-bs-backdrop="static"
        id="walletamountModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="loginCloseBtn"
            ></button>
            <div className="modal-body step-form-right login-form">
              <h3>Add Amount</h3>
              <div className="input-group">
                <input
                  type="text"
                  className={
                    "form-control w-75" +
                    (amounterror != true ? " error-warning" : "")
                  }
                  aria-label="Text input with dropdown button"
                  placeholder="Enter Amount"
                  inputmode="numeric"
                  maxlength="10"
                  value={amount}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <button className="btn send-otp-btn" onClick={()=>HandleOrder(amount)}>Make Payment</button>
            </div>
          </div>
          <button
            className="btn send-otp-btn"
            data-bs-target="#walletamountModal"
            data-bs-toggle="modal"
            id="amountwallet"
            style={{ display: "none" }}
          >
            click
          </button>
        </div>
      </div>
    </>
  );
};

export default WalletDetails;
