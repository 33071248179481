import React,{useState,useEffect} from 'react';
import {Link,useLocation} from 'react-router-dom';
import Header from '../layouts/header';
import Footer from '../layouts/Footer';
import Modal from '../layouts/modal';
import PageCounter from './counter';
import PageBlog from './pageblog';
import PageSubscribe from './pagesubscribe';
import axios from 'axios';
import {api_url,web_url,img_url} from '../basic';
import { useSelector, useDispatch } from 'react-redux';
import {selectUser,logout,selectPage_id,page_id} from '../../app/userSlice';
const PlanDetails=({setIsView,isView,plan_id,setIsCalcy,isCalcy})=>{
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const [planid,setPlanid]=useState(plan_id);	
	const [planData,setPlanData]=useState('');
	useEffect(()=>{
		loadPlan();
	},[]);
	const loadPlan = async()=>{
		try{
			const pdata = await axios.get(api_url+'view-buy-plan?id='+`${planid}`,{ headers: {"Authorization" : `Bearer ${user.token}`}});
			const data_js = JSON.parse(JSON.stringify(pdata.data));
			if(data_js.status==1){
				setPlanData(data_js.data.PlanList);
			}
		}catch(e){
			return false;
		}
	}

	const backOrder=()=>{
		setIsView(false);
	}


	const ViewCalcy=(id)=>{
		setIsCalcy(true);
	}
	


	return (
			<>			
		          <div className="row justify-content-center">
		            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
		              <div className="card">
		                <div className="card-body">
		                   <div className="d-flex align-items-center justify-content-between mb-2">
			                <h2 className="mb-0"> <i className="fa fa-angle-left back-icon me-2 float-start" onClick={backOrder}></i>Plan Details</h2>
			                <div>
			                <button type="button" className="btn btn-sm add-on me-2" onClick={()=>ViewCalcy()}><i className="fa fa-plus me-2"></i>Add On</button>
			                <button type="button" className="btn btn-sm btn-outline-danger rounded-pill"><i className="fa fa-file-pdf-o"></i> Invoice</button>
			              </div>
			              </div>
		                  <hr/>
		                  <div className="pl_detail_main">
		                  <div className="pl_detail">
		                    <h4>Plan</h4>
		                    <p className="act">{planData && planData.plan_details.title}</p>
		                  </div>
		                  <div className="pl_detail">
		                    <h4>Plan Price</h4>
		                    <p>&#8377; {planData && planData.plan_details.new_price}</p>
		                  </div>
		                  <div className="pl_detail">
		                    <h4>Validity</h4>
		                    <p>{planData && planData.plan_details.duration} {planData && planData.plan_details.duration_type==1?<>
		                    Days</>:planData && planData.plan_details.duration_type==2?<>Month</>:planData && planData.plan_details.duration_type==3?<>Years</>:''}</p>
		                  </div>
		                   <div className="pl_detail">
		                    <h4>User</h4>
		                    <p>10</p>
		                  </div>		                  
		                </div>
		                  <hr/>
		                  <div className="plan-benfit">
		                    <h4>Plan Benefit:</h4>
		                    <div className="row">
		                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
		                    <ul>
		                    {
		                    	planData && planData.plan_details.features.split(',').map((item)=>(
		                    		<>
		                    			 <li>{item}</li>
		                    		</>
		                    	))
		                    }
		                      
		                    </ul>
		                  </div>
		                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
		                    <img src="img/plan-benefit.png" className="img-fluid" alt="plan"/>
		                  </div>
		                  </div>
		                </div>
		              </div>
		            </div>
		          </div>
		        </div>
		    
	
				
			</>
		);
}
export default PlanDetails;

