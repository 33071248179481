import React from "react";
import Header from "../layouts/header";
import Footer from "../layouts/Footer";
import Modal from "../layouts/modal";
import PageCounter from "./counter";
import PageBlog from "./pageblog";
import PagePricing from "./PagePricing";
import PageFAQ from "./pagefaq";
import TrustedPartner from "./trusted";
const Blog = () => {
  return (
    <>
      <section id="blog-banner">
        <div className="container-fluid">
          <div className="blog-back">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="blog-banner-heading">
                  <h2>Our Blogs</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PageBlog />
      <TrustedPartner heading={"20,000+ Companies Trust us"} />
      <PagePricing />
      <PageFAQ />
    </>
  );
};
export default Blog;
