import React,{useState,useEffect} from 'react';
import {web_url,api_url,img_url} from '../basic';
import axios from 'axios';
import Slider from "react-slick";
const ClientTestimonials=()=>{
	const [testimonials,setTestimonials]=useState([]);
	useEffect(()=>{
		loadtestimonial();
	},[]);
	const loadtestimonial=async()=>{
		try{
			const faq_list = await axios.get(web_url+'website-testimonial');
			const data = JSON.parse(JSON.stringify(faq_list.data));
			if(data.status==200){
				setTestimonials(data.result);
			}
		}catch(e){
			return false;
		}
	}

let settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
      	arrows:false,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  };

	return(
		<>
			 <section id="testimonials-section">
				        <div className="testimonial-heading">
				        <h3>Words Straight from Our Satisfied Clients </h3>
				      </div>
				        <div className="container-fluid">
				          <div className="row">
				            <div className="testimonial-inner">
				              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				                <div className="row" id="testimonials_crousel">
				                 <Slider {...settings}>
				                {
			                	testimonials.map((item,index)=>(
		                			<>
		                				<div className="item br-right pl-left">
						                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
						                    <div className="testimonial-text">
						                      <img src={img_url+item.image} className="img-fluid text-center" width="70px" alt={item.name}/>
						                      <p>{item.description}</p>
						                      <h5>{item.name}</h5>
						                      <span>{item.city_country}</span>
						                    </div>
						                  </div>						                 
					                  	</div>
		                			</>
			                		))
				                }			                	

				                   </Slider>
				                  
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>
				      </section>
		</>
		);
}
export default ClientTestimonials;
