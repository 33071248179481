import React,{useState,useEffect} from 'react';
import Modal from './modal';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {selectUser,logout,selectPage_id,page_id} from '../../app/userSlice';
import axios from 'axios';
import {web_url,img_url} from '../basic';
const Footer=()=>{
	const user = useSelector(selectUser);
	const [socialLink,setSocialLink]=useState([]);
	const [companydata,setCompanydata]=useState({
		app_store_link:'',
		play_store_link:'',
		copy_right:''
	});
	const {app_store_link,play_store_link,copy_right}=companydata;
	useEffect(()=>{
		loadSocialLink();
		companydetails();
	},[])
	const loadSocialLink=async()=>{
		try{
				const social_link=await axios.get(web_url+'social-icon-list');
				const data = JSON.parse(JSON.stringify(social_link.data));
				if(data.status==200){
					setSocialLink(data.result);
				}
		}catch(e){
			return false;
		}
	}

	const companydetails = async()=>{
		try{
			const social_link=await axios.get(web_url+'show-company');
			const data = JSON.parse(JSON.stringify(social_link.data));
			if(data.status==200){
				setCompanydata({...companydata,app_store_link:data.result.app_store_link,play_store_link:data.result.play_store_link,copy_right:data.result.copy_right});
			}
		}catch(e){
			return false;
		}
	}
	
	return(
			<>
				<footer>
		        <div className="container-fluid">
		          <div className="row">
		            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
		              <div className="footer-logo-part">
		                <img src="img/close-logo-white.png" className="img-fluid" width="184px"/>
		                <p>Now, monitor your team activities anytime, anywhere and boost your sales.</p>
		              </div>
		            </div>
		            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
		              <div className="footer-links">
		                <h4>Company</h4>
		                <ul>
		                  <li><Link to="/about-us">About Us</Link></li>
		                  <li><Link to="/#features-section">Features</Link></li>
		                  <li><Link to="/contact-us">Contact Us</Link></li>
		                </ul>
		              </div>
		            </div>
		            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
		              <div className="footer-links">
		                <h4>Support</h4>
		                <ul>
		                  <li><Link to="/refund-policy">Refund Policy</Link></li>
		                  <li><Link to="/privacy-policy">Privacy Policy</Link></li>
		                  <li><Link to="/term-condition">Terms & Condition</Link></li>
		                </ul>
		              </div>
		            </div>
		            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
		              <div className="footer-links">
		                <h4>Our Works</h4>
		                <ul>
		                  <li><Link to="/pricing">Pricing</Link></li>
		                  {user && user ? <li><Link to="/profile">Profile</Link></li> : <li><Link to="/signup">Signup</Link></li>}		                 
		                </ul>
		              </div>
		            </div>
		            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
		              <div className="footer-links">
		                <h4>Follow Us</h4>
		                <ul className="social-icon mb-0">
		                {
		                	socialLink.map((item,index)=>(
		                		<>
		                			<li><Link to={item.link} target="_blank"><img src={img_url+item.icon} alt={item.title} className="img-fluid me-3" width="29px"/></Link></li>
		                		</>
		                	))
		                }
		                 
		                </ul>
		                <h4>Download the App</h4>
		                <ul className="social-icon justify-content-start">
		                  <li><Link to={app_store_link} target="_blank"><img src="img/apple-logo.png" className="img-fluid me-3" width="29px"/></Link></li>
		                  <li><Link to={play_store_link} target="_blank"><img src="img/play-store.png" className="img-fluid" width="25px"/></Link></li>
		                </ul>
		              </div>
		            </div>
		          </div>
		        </div>
		        <div className="copyright">
		            <p>©{new Date().getFullYear()} {copy_right}</p>
		        </div>
		      </footer>
		      <Modal/>
			</>
		)
}
export default Footer;