import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Header from '../layouts/header';
import Footer from '../layouts/Footer';
import Modal from '../layouts/modal';
import axios from 'axios';
import PlanDetails from './plan-detail';
import EditProfile from './editprofile';
import WalletDetails from './walletdetails';
import PriceCalcy from './pricecalculator';
import {api_url,web_url,img_url} from '../basic';
import { useSelector, useDispatch } from 'react-redux';
import {selectUser,logout,selectPage_id,page_id} from '../../app/userSlice';
import {ToastContainer, toast } from 'react-toastify';
import {selectPath,create_path} from '../../app/pathSlice';
import 'react-toastify/dist/ReactToastify.css';
const Profile=()=>{
	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const [currentPlan,setCurrentPlan]=useState('');
	const [upcomingPlan,setUpcomingPlan]=useState([]);
	const [isView,setIsView]=useState(false);
	const [isEdit,setIsEdit]=useState(false);
	const [isWallet,setIsWallet]=useState(false);
	const [isCalcy,setIsCalcy]=useState(false);
	const [plan_id,setPlan_id]=useState('');
	const [companyData,setCompanyData]=useState('');
	const [isplan,setIsplan]=useState(false);
	const [walletData,setWalletData]=useState(null);
	useEffect(()=>{
		loadUpcomingPlan();
		loadCurrentPlan();
		loadCompanyProfile();
		loadwallet();
		dispatch(create_path({
			path:window.location.pathname
		}))
	},[isplan]);
	
	const handleLogout = ()=>{		
		localStorage.removeItem('web_dname');
		localStorage.removeItem('web_token');
		dispatch(logout());
		navigate('/');
	}	
	const loadUpcomingPlan=async()=>{
		try{

			const upcmp = await axios.get(api_url+'plan-upcoming-running?company_id='+`${user.cid}`,{ headers: {"Authorization" : `Bearer ${user.token}`}});
			const upcmp_data = JSON.parse(JSON.stringify(upcmp.data));
			if(upcmp_data.status==1){
				setUpcomingPlan(upcmp_data.data.PlanList);
			}

		}catch(e){
			return false;
		}
	}
	const loadCurrentPlan=async()=>{
		try{

			const upcmp = await axios.get(api_url+'plan-running?company_id='+`${user.cid}`,{ headers: {"Authorization" : `Bearer ${user.token}`}});
			const upcmp_data = JSON.parse(JSON.stringify(upcmp.data));
			if(upcmp_data.status==1){
				setCurrentPlan(upcmp_data.data.PlanList);
			}

		}catch(e){
			return false;
		}
	}

	const loadCompanyProfile=async()=>{
		try{

			const upcmp = await axios.get(api_url+'company-profile?id='+`${user.cid}`,{ headers: {"Authorization" : `Bearer ${user.token}`}});
			const upcmp_data = JSON.parse(JSON.stringify(upcmp.data));
			if(upcmp_data.status==1){
				setCompanyData(upcmp_data.data.company_profile);
			}

		}catch(e){
			return false;
		}
	}

	const viewplan=(id)=>{
		setIsView(true);
		setIsWallet(false);
		setIsEdit(false);
		setIsCalcy(false);
		setPlan_id(id);		
	}

	const ViewWallet=(id)=>{
		setIsView(false);
		setIsWallet(true);
		setIsEdit(false);
		setIsCalcy(false);
	}

	const EditPro=(id)=>{
		setIsView(false);
		setIsWallet(false);
		setIsEdit(true);
		setIsCalcy(false);
		setPlan_id(id);		
	}

	const ViewCalcy=(id)=>{
		setIsView(false);
		setIsWallet(false);
		setIsEdit(false);
		setIsCalcy(true);
	}

	const activate_buy_plan=async(id,cmp_id)=>{
		if(window.confirm("Are you sure want to activate this")){
		try{

			const upcmp = await axios.get(api_url+'activate-upcoming-plan?buy_plan_id='+`${id}`+'&company_id='+`${cmp_id}`,{ headers: {"Authorization" : `Bearer ${user.token}`}});
			const upcmp_data = JSON.parse(JSON.stringify(upcmp.data));
			if(upcmp_data.status==1){
				toast.success('Plan Activated Successfull');
				setIsplan(true);
			}

		}catch(e){
			toast.error('something went wrong');
		}
	}else{
		return false;
	}
	}

/*------------wallet details--------*/
	const loadwallet=async()=>{
		try{

			const upcmp = await axios.get(api_url+'wallet-history?cmp_id='+`${user.cid}`,{ headers: {"Authorization" : `Bearer ${user.token}`}});
			const upcmp_data = JSON.parse(JSON.stringify(upcmp.data));
			if(upcmp_data.status==1){
				setWalletData(upcmp_data.data);
			}

		}catch(e){
			return false;
		}
	}

	
	return (
			<>
			<ToastContainer />			  
				<section id="dashboard-section">
				   <div className="container-fluid">
				      <div className="row">
				         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
				            <div className="profile-info mb-4">
				               <div className="profile-img text-center">
				                  <img src="img/dashboard-profile-img.png" className="img-fluid rounded-circle" />
				               </div>
				               <h1>{companyData && companyData.dir_name} <img src="img/secure-icon.png" width="12px" className="img-fluid"/></h1>
				               <p>{companyData && companyData.mobile}</p>
				               <p>{companyData && companyData.email}</p>
				               <Link to="#" className="btn edit-profile w-100" onClick={EditPro}>
				               Manage Profile</Link>
				               <button type="button" className="btn btn-primary w-100 mt-3" onClick={()=>ViewWallet()} style={{'backgroundColor':'#00a445','border':"#00a445 "}}>Wallet ( <i className="fa fa-inr"></i> {walletData && walletData.available_amt} )</button>
				               <button type="button" className="btn btn-warning w-100 mt-3" onClick={()=>ViewCalcy()} style={{'backgroundColor':'#efa434','border':"#efa434","color":"#fff"}}>Manage Plan</button>
				               <button type="button" className="btn logout w-100" onClick={()=>handleLogout()}>Logout</button>
				            </div>
				         </div>
				         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
				            {isEdit==true?<>
				            <EditProfile setIsEdit={setIsEdit} isEdit={isEdit} companydata={companyData} cmpid={user.cid}/>
				            </>:
				            <>
				            {isWallet==true?<WalletDetails setIsWallet={setIsWallet} isWallet={isWallet}/>:
				            <>
				            <div className="row mb-2">
				               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-3">
				                  <div className="card h-100">
				                     <div className="card-body owned">
				                     <h4>Current Plan</h4>
				                     {
				                     	currentPlan && currentPlan?<>				                        
				                        <p className="plan">₹ {currentPlan && currentPlan.plan_details.new_price} {currentPlan && currentPlan.plan_details.title} <span className="act ms-2">Active</span></p>
				                        <p>Exprie on {currentPlan.valid_upto}</p>
				                        </>
				                        :
				                        <><span>Don't have Plan</span></>
				                    }
				                     </div>
				                  </div>
				               </div>
				               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-3">
				                  <div className="card h-100">
				                     <div className="card-body upcoming">
				                     {upcomingPlan.length>0?<>
				                        <div className="d-flex align-items-center justify-content-between">
				                           <h4>Upcoming Plan</h4>
				                           <p>{upcomingPlan.length>2?'2+':upcomingPlan.length}</p>
				                        </div>
				                        {
				                        upcomingPlan.slice(0,2).map((item,index)=>(
				                        <>
				                        <h6>{item.plan_details.title} <span>₹ {item.plan_details.new_price}</span></h6>
				                        </>
				                        ))
				                        }
				                        </>
				                        :
				                       	<>
				                       	 <div className="d-flex align-items-center justify-content-between">
				                           <h4>Upcoming Plan</h4>
				                           <p>0</p>
				                        </div>
				                        <span>Don't have plan</span>
				                       	</>
				                        }                
				                     </div>
				                  </div>
				               </div>
				               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-3">
				                <div class="card h-100">
				                  <div class="card-body wallet">
				                    <div class="d-flex align-items-center justify-content-between">
				                  <h4>Wallet</h4>
				                  <button type="button" class="btn wallet btn-sm" onClick={()=>ViewWallet()}><i class="fa fa-angle-right"></i></button>
				                </div>
				                <h6>Total Amt<span> ₹ {walletData && walletData.total_credit} </span></h6>
				                <h6>Used Amt<span> ₹ {walletData && walletData.used_amt} </span></h6>
				                 <h6>Available Amt<span> ₹ {walletData && walletData.available_amt} </span></h6>
				                </div>
				              </div>
				              </div>
				            </div>
				            {
				               upcomingPlan.length>0?<>

				            {
				            isCalcy==true?<><PriceCalcy setIsCalcy={setIsCalcy} isCalcy={isCalcy}/></>:
				            <>
				            {
				            isView==true?<>
				            <PlanDetails setIsView={setIsView} isView={isView} plan_id={plan_id} setIsCalcy={setIsCalcy} isCalcy={isCalcy}/>
				            </>:<>
				            <div className="card">
				               <div className="card-body">
				                  <div class="d-flex align-items-center justify-content-between mb-4">
					                <h2 class="mb-0">Order Status</h2>
					                <button type="button" class="btn btn-sm add-on" onClick={()=>ViewCalcy()}><i class="fa fa-plus me-2"></i>Add On</button>
					              </div>
				                  <div className="table-responsive bg-white">
				                     <table className="table">
				                        <thead>
				                           <tr>
				                              <th scope="col">Order</th>
				                              <th scope="col">Plan</th>
				                              <th scope="col">No of Devices</th>
				                              <th scope="col">Price in(RS)</th>
				                              <th scope="col">Active From</th>
				                              <th scope="col">Expire On</th>
				                              <th scope="col">Status</th>
				                              <th scope="col">Action</th>
				                           </tr>
				                        </thead>
				                        <tbody>
				                           {
				                           upcomingPlan.length>0?
				                           <>
				                           {
				                           upcomingPlan.map((item,index)=>(
				                           <>
				                           <tr>
				                              <td>
				                                 <Link to="#" onClick={()=>
				                                 viewplan(item.id)}>{item.id}</Link>
				                              </td>
				                              <td>{item.plan_details.title}</td>
				                              <td>5</td>
				                              <td>{item.plan_details.new_price}</td>
				                              <td>{item.valid_from}</td>
				                              <td>{item.valid_upto}</td>
				                              <td>
				                                 { item.status==1?
				                                 <span className="badge custom_badge_blue">Active</span>	
				                                 :item.status==2?
				                                 <span className="badge custom_badge_red">Expired</span>
				                                 :item.status==3?
				                                 <span className="badge custom_badge_warning">Upcoming</span>
				                                 :null
				                                 }		       
				                              </td>
				                              <td>{item.status==3?<><button className="btn btn-outline-success btn-sm me-2" onClick={(e)=>activate_buy_plan(item.id,user.cid)} data-bs-toggle="tooltip" data-bs-title="Activate Your Plan"><i class="fa fa-check"></i></button><button className="btn btn-outline-danger btn-sm" data-bs-toggle="tooltip" data-bs-title="Print Invoice"><i class="fa fa-file-pdf-o"></i></button></>:<><button className="btn btn-outline-danger btn-sm" data-bs-toggle="tooltip" data-bs-title="Print Invoice"><i class="fa fa-file-pdf-o"></i></button></>}</td>
				                           </tr>
				                           </>
				                           ))
				                           }
				                           </>
				                           :
				                           <>
				                           <tr>
				                              <td colSpan="6">
				                                 <p>No Plan Available</p>
				                              </td>
				                           </tr>
				                           </>
				                           }
				                        </tbody>
				                     </table>
				                  </div>
				               </div>
				            </div>
				            </>
				        }
				        </>
				            }
				            </>
				            :
				           <>
				           	 <div class="alert alert-danger d-flex align-items-center justify-content-between mb-4" role="alert">
					                <div>
					                <button type="button" class="btn btn-danger btn-sm me-3"><i class="fa fa-shopping-cart"></i></button>Start your subscription plan with your business requirements</div>
					                <div>
					                 <Link to="/pricing" type="button" class="btn btn-danger btn-sm">Buy Now</Link>
					               </div>
					            </div>
				           </>
				        }
				            </>
				            }
				            </>
				        }
				         </div>
				      </div>
				   </div>
				</section>
				</>
			
		);
}
export default Profile;

