import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {api_url,web_url,img_url} from '../basic';
const PageSubscribe=()=>{
	const [subemailError,setSubemailError]=useState(true);
	const [request,setRequest]=useState({		
		email:''				
	})
	const {email}=request;
	const handlechange=(e)=>{
		setRequest({...request,[e.target.name]:e.target.value});
	}

	const ValidateRequest=()=>{
		
		if(email===''){
			setSubemailError(false);
		}else{
			setSubemailError(true);
		}
		
		if(email===''){
			return false;
		}else{
			return true;
		}
	}

	const handledemo=async()=>{
	if(ValidateRequest()==true){		
			try{
				let url=window.location.href;
				console.log(url);
				let form = {"subscribe_email":email,"url":url}
				const otp_sent = await axios.post(web_url+'add-subscribe',form);
				const otp_Data = JSON.parse(JSON.stringify(otp_sent.data));
				if(otp_Data.status==200){					
					toast.success('subscribe successfull')	
				}else{
					toast.error('something went wrong');
				}
			}catch(e){
				return false;
			}
	}else{
		return false;
	}
}
	return(
		<>
			 <section id="subscribe-section">
				        <div className="container">
				          <div className="subscribe-inner">
				          <div className="row justify-content-center">
				            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
				              <div className="subscribe-text">
				                <h3>Subscribe to my blog.</h3>
				                <p>I post fresh content every week.</p>
				                <div className="input-group">
				                <input type="text" className={"form-control"+(subemailError!=true?' error-warning':'')} placeholder="Email address" aria-describedby="button-addon2" name="email" onChange={(e)=>handlechange(e)}/>
				                <button className="btn subscribe" type="button" id="button-addon2" onClick={handledemo}>SUBSCRIBE</button>
				              </div>
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				      </section>
		</>
		);
}
export default PageSubscribe;