import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  logout,
  selectPage_id,
  page_id,
} from "../../app/userSlice";
import { selectPath, create_path } from "../../app/pathSlice";
import { web_url, api_url, img_url } from "../basic";
import axios from "axios";
const Header = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const pages = useSelector(selectPage_id);
  const [logo, setLogo] = useState("");
  const [menu, setMenu] = useState([]);
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem("web_dname");
    localStorage.removeItem("web_token");
    dispatch(logout());
    navigate("/");
  };
  useEffect(() => {
    websitelogo();
    websitemenu();
  }, []);
  const websitelogo = async () => {
    try {
      const logo = await axios.get(web_url + "website-logo");
      const weblogo = JSON.parse(JSON.stringify(logo.data));
      if (weblogo.status == 200) {
        setLogo(img_url + weblogo.result.website_logo);
        localStorage.setItem("logo_url", img_url + weblogo.result.website_logo);
      }
    } catch (e) {
      return false;
    }
  };

  const websitemenu = async () => {
    try {
      const menuList = await axios.get(web_url + "website-menu");
      const webmenu = JSON.parse(JSON.stringify(menuList.data));
      if (webmenu.status == 200) {
        setMenu(webmenu.result);
      }
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src={logo}
              alt="Logo"
              width="185px"
              className="img-fluid d-inline-block align-text-top"
            />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
            <ul className="navbar-nav mb-2 mb-lg-0 ms-auto align-items-center">
              {menu.map((item, index) => (
                <>
                  <li className={"nav-item"+(item.child.length>0?' dropdown':'')}>
                    <Link
                      to={"/" + item.page_slug}
                      className={"nav-link"+(item.child.length>0?' dropdown-toggle':'')}
                      role="button"
                      data-bs-toggle={item.child.length>0?'dropdown':''}
                      aria-expanded={item.child.length>0?'false':''}
                      target={item.page_name == "Blog" ? "_blank" : ""}
                    >
                      {item.page_name}
                    </Link>
                     {item.child.length>0?<>
                     <ul className="dropdown-menu">                     
                     {
                    item.child.map((item_child)=>(
                      <>
                        
                        <li>
                          <Link className="dropdown-item" to={item_child.page_slug}>
                            {item_child.page_name}
                          </Link>
                        </li>
                       
                      </>
                    ))}
                    </ul>
                   </>
                   :null}

                  </li>                  
                </>

              ))}

             

              {user ? (
                <>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="img/dashboard-profile-img.png"
                        className="img-fluid me-2"
                        width="35px"
                      />
                      {user ? user.dirname : ""}
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/profile">
                          Profile
                        </Link>
                      </li>
                      {/*<li><Link className="dropdown-item" to="/plan-detail">Plan</Link></li>*/}
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => handleLogout(e)}
                        >
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link
                      className="nav-link login-btn"
                      data-bs-toggle="modal"
                      to="#exampleModalToggle"
                      role="button"
                      id="LoginBtn"
                    >
                      Login
                    </Link>
                  </li>
                </>
              )}

              <li className="nav-item">
                <Link className="nav-link request-btn" to="/request-demo">
                  Request A Demo
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
