import React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/Footer';
import Modal from '../layouts/modal';
import {Link} from 'react-router-dom';
const NotFound=()=>{
	return(
			<>
			
					<section id="error">
					   <div className="container">
					      <div className="row justify-content-center">
					         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
					            <div className="error-inner">
					               <img src="img/404-img.png" width="700px" className="img-fluid"/>
					               <div className="error-text">
					                  <p>This page <span>doesn't exist</span> or removed!<br/> 
					                     We suggest you back to home</p>
					                  <Link to="/" className="btn go-home">Go to Home</Link>   
					               </div>
					            </div>
					         </div>
					      </div>
					   </div>
					</section>
			
				<Modal/>
			</>
		);
}
export default NotFound;