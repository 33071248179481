import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {api_url} from '../basic';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {selectUser} from '../../app/userSlice';
import {create_plan} from '../../app/pricingSlice';
const PagePricing=()=>{
	const [plans,setPlans]=useState([]);
	const [planType,setPlanType]=useState(2);
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	useEffect(()=>{
		loadplan(planType);
	},[]);
	const loadplan = async(planType)=>{
		try{
			const plan_data = await axios.get(api_url+'pricing-plan-listing-app?planType='+`${planType}`,{headers:{"Authorization":"close_call_key"}});
			const pricing_plan = JSON.parse(JSON.stringify(plan_data.data));
			if(pricing_plan.status==1){
				setPlans(pricing_plan.data.plan_list);				
				dispatch(create_plan({plan:pricing_plan.data.plan_list}));				
			}

		}catch(e){
			return null;
		}
	}

	const handleplantype=(e)=>{
		if(e.target.checked){
			setPlanType(e.target.value);
			loadplan(e.target.value);			
		}else{
			setPlanType(2);
			loadplan(2);
		}
	}

	const handleLogin=()=>{
		document.getElementById('LoginBtn').click();
	}

	return(
			<>
				 <section id="pricing-section">
				        <div className="pricing-heading">
				          <h3>SIMPLE PRICING. GREAT VALUE.</h3>
				          <p>Pay securely online and manage the booking via desktop or via the mobile app.</p>
				          <div className="form-check form-switch">
				          <span className="me-4">Monthly</span>
				          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value="3" onChange={(e)=>handleplantype(e)}/>
				          <span className="ms-4">Annually</span>
				          </div>
				        </div>
				        <div className="container">
				          <div className="row">
				          {
				          	plans.map((item,index)=>(
				          		<>
				          			<div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
						              <div className="pricing-card">						             	
						                <h4>{item.title}</h4>
						                <p>{item.description}</p>
						                 <div className="price-cut">
											<span>₹{item.old_price}</span><span className="price-span">SAVE {item.save_amount}%</span>
										 </div>
						                <h2>{item.currency_type==1?'₹':'$'}{item.new_price} <span>/{item.duration_type==1?'Days':item.duration_type==2?'Months':'Years'}</span></h2>
						               <Link to={user?"/checkout?pid="+item.upid+"&cid="+user.cid:null} onClick={user==null?handleLogin:null}> <button className="btn plan" data={item.id}>Buy Now</button></Link>
						                <ul>
						                {
						                	item.plan_features.map((item2,index2)=>(
						                		<>
						                			 <li><img src="img/tick-icon-yellow.png" width="20px" className="img-fluid me-3" alt={item2}/>{item2}</li>
						                		</>
						                	))
						                }	                 
						                 
						                </ul>
						              </div>
						            </div>						            
				          		</>
				          	))
				          }			            
				           
				          </div>
				        </div>
				      </section>
			</>
		);
}
export default PagePricing;